<template>
    <div v-if="analysis" class="mt-4">
        <div class="mt-2 bg-gray-800 rounded-lg p-3">
            <div class="flex flex-col gap-2">
                <div class="flex items-center space-x-2">
                    <BoltIcon v-tooltip="'Análise gerada por IA'" class="h-5 w-5 text-purple-500" />
                    <span 
                        class="text-sm font-medium"
                        :class="{
                            'text-red-500': analysis.confidence < 30,
                            'text-yellow-500': analysis.confidence >= 30 && analysis.confidence < 70,
                            'text-green-500': analysis.confidence >= 70
                        }"
                    >
                        Confiança: {{ analysis.confidence }}%
                    </span>
                    <span :class="{
                        'text-red-500': analysis.analysis === 'Reprovar',
                        'text-green-500': analysis.analysis === 'Aprovar',
                        'text-yellow-500': analysis.analysis === 'Atenção',
                        'dark:text-white': analysis.analysis === 'Análise manual',
                    }">{{ analysis.analysis }}</span>
                </div>
                <div class="text-sm text-gray-400">
                    <TypewriterText 
                        :text="analysis.reason"
                        :speed="5"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BoltIcon } from '@heroicons/vue/24/solid'
import TypewriterText from '@/components/common/TypewriterText.vue'

export default {
    props: ['analysis'],
    name: 'SystemAnalysis',
    components: {
        BoltIcon,
        TypewriterText
    },
}
</script> 