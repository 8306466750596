<template>
    <div>
        <h3 class="my-4 text-xl leading-6 dark:text-white">
            Perfil
        </h3>
    </div>
    <div
        class="mb-3 rounded-xl bg-slate-50 p-3 dark:bg-slate-800/25"
    >
        <div class="w-full">
            <PasskeysList />
        </div>
    </div>
</template>

<script>

import PasskeysList from "../../components/user/passkeys/PasskeysList.vue";

export default {
    components: {
        PasskeysList,
    },
};
</script>
