<template>
    <router-view></router-view>
    <NotificationGroup group="error">
        <div
            class="pointer-events-none fixed inset-0 flex items-start justify-end p-6 px-4 py-6"
            style="z-index: 100"
        >
            <div class="w-full max-w-sm">
                <Notification
                    v-slot="{ notifications }"
                    enter="transform ease-out duration-300 transition"
                    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-500"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                    move="transition duration-500"
                    move-delay="delay-300"
                >
                    <div
                        class="mx-auto mt-4 flex w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-md"
                        v-for="notification in notifications"
                        :key="notification.id"
                    >
                        <div
                            class="flex w-12 items-center justify-center bg-red-900"
                        >
                            <svg
                                class="h-6 w-6 fill-current text-white"
                                viewBox="0 0 40 40"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z"
                                />
                            </svg>
                        </div>

                        <div class="-mx-3 px-4 py-2">
                            <div class="mx-3">
                                <span class="font-semibold text-red-900">{{
                                    notification.title
                                }}</span>
                                <p class="text-sm text-gray-600">
                                    {{ notification.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </Notification>
            </div>
        </div>
    </NotificationGroup>
    <NotificationGroup group="success">
        <div
            class="pointer-events-none fixed inset-0 flex items-start justify-end p-6 px-4 py-6"
            style="z-index: 100"
        >
            <div class="w-full max-w-sm">
                <Notification
                    v-slot="{ notifications }"
                    enter="transform ease-out duration-300 transition"
                    enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
                    enter-to="translate-y-0 opacity-100 sm:translate-x-0"
                    leave="transition ease-in duration-500"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                    move="transition duration-500"
                    move-delay="delay-300"
                >
                    <div
                        class="mx-auto mt-4 flex w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-md"
                        v-for="notification in notifications"
                        :key="notification.id"
                    >
                        <div
                            class="flex w-12 items-center justify-center bg-green-600"
                        >
                            <svg
                                class="h-6 w-6 fill-current text-white"
                                viewBox="0 0 40 40"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z"
                                />
                            </svg>
                        </div>

                        <div class="-mx-3 px-4 py-2">
                            <div class="mx-3">
                                <span class="font-semibold text-green-600">{{
                                    notification.title
                                }}</span>
                                <p class="text-sm text-gray-600">
                                    {{ notification.text }}
                                </p>
                            </div>
                        </div>
                    </div>
                </Notification>
            </div>
        </div>
    </NotificationGroup>
</template>

<script>
import Highcharts from "highcharts";

import { mapWritableState } from "pinia";
import { mapActions } from "pinia";
import { Store } from "./stores/store";
import { ClientJS } from "clientjs";
import TictoLoading from "./components/layouts/TictoLoading.vue";
import Card from "./components/layouts/Card.vue";
import Visitor from "./components/fingerprint/Visitor.vue";
import LoadVisitorData from "./mixins/LoadVisitorData.vue";
export default {
    components: { Visitor, Card, TictoLoading },

    mixins: [LoadVisitorData],

    computed: {
        ...mapWritableState(Store, [
            "authUser",
            "cancel_tokens",
            "dark",
            "permissions",
        ]),
    },

    mounted() {
        this.setHighCharts();
        this.$router.beforeEach((to, from, next) => {
            this.cancelPendingRequests();
            next();
        });

        this.$router.afterEach((to, from) => {
            try {
                if (window.tracker) {
                    window.tracker.track('page_view', { ...this.authUser ? { linked_id: this.authUser.id } : { } });
                }
            } catch (error) {
                console.log(error);
            }
        });

        const isDark = localStorage.getItem("dark");
        this.dark = isDark === "true";

        axios.interceptors.request.use(
            (config) => {
                //  Generate cancel token source
                let source = axios.CancelToken.source();

                // Set cancel token on this request
                config.cancelToken = source.token;

                // Add to vuex to make cancellation available from anywhere
                this.cancel_tokens.push(source);

                return config;
            },
            (error) => {
                if (error.response.status === 401) {
                    if (error.response.data.expired) {
                        this.$toast.error(error.response.data.message);
                    }
                    this.$router.push({ name: "login" });
                }

                return Promise.reject(error);
            },
        );

        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (!error.response) {
                    return Promise.reject(error);
                }

                // Handle Session Timeouts
                if (error.response.status === 401) {
                    if (error.response.data.expired) {
                        this.$notify(
                            {
                                group: "error",
                                title: "Senha Expirada",
                                text: error.response.data.message,
                            },
                            4000,
                        );
                    }
                    this.$router.push({ name: "login" });
                }

                return Promise.reject(error);
            },
        );

        var client = new ClientJS();
        axios.defaults.headers.common["X-Device-fingerprint"] =
            client.getFingerprint();
        axios.defaults.headers.common["X-Os-version"] = client.getOSVersion();
        // axios.defaults.headers.common['X-Os'] = client.getO();
        axios.defaults.headers.common["X-Device"] = client.getDevice();
        axios.defaults.headers.common["X-Device-type"] = client.getDeviceType();
    },

    methods: {
        setHighCharts() {
            Highcharts.theme = {
                colors: [
                    "#2b908f",
                    "#90ee7e",
                    "#f45b5b",
                    "#7798BF",
                    "#aaeeee",
                    "#ff0066",
                    "#eeaaee",
                    "#55BF3B",
                    "#DF5353",
                    "#7798BF",
                    "#aaeeee",
                ],
                chart: {
                    backgroundColor: {
                        linearGradient: { x1: 0, y1: 0, x2: 1, y2: 1 },
                        stops: [
                            [0, "#2a2a2b"],
                            [1, "#3e3e40"],
                        ],
                    },
                    style: {
                        fontFamily: "'Unica One', sans-serif",
                    },
                    plotBorderColor: "#606063",
                },
                title: {
                    style: {
                        color: "#E0E0E3",
                        textTransform: "uppercase",
                        fontSize: "20px",
                    },
                },
                subtitle: {
                    style: {
                        color: "#E0E0E3",
                        textTransform: "uppercase",
                    },
                },
                xAxis: {
                    gridLineColor: "#707073",
                    labels: {
                        style: {
                            color: "#E0E0E3",
                        },
                    },
                    lineColor: "#707073",
                    minorGridLineColor: "#505053",
                    tickColor: "#707073",
                    title: {
                        style: {
                            color: "#A0A0A3",
                        },
                    },
                },
                yAxis: {
                    gridLineColor: "#707073",
                    labels: {
                        style: {
                            color: "#E0E0E3",
                        },
                    },
                    lineColor: "#707073",
                    minorGridLineColor: "#505053",
                    tickColor: "#707073",
                    tickWidth: 1,
                    title: {
                        style: {
                            color: "#A0A0A3",
                        },
                    },
                },
                tooltip: {
                    backgroundColor: "rgba(0, 0, 0, 0.85)",
                    style: {
                        color: "#F0F0F0",
                    },
                },
                plotOptions: {
                    series: {
                        dataLabels: {
                            color: "#F0F0F3",
                            style: {
                                fontSize: "13px",
                            },
                        },
                        marker: {
                            lineColor: "#333",
                        },
                    },
                    boxplot: {
                        fillColor: "#505053",
                    },
                    candlestick: {
                        lineColor: "white",
                    },
                    errorbar: {
                        color: "white",
                    },
                },
                legend: {
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    itemStyle: {
                        color: "#E0E0E3",
                    },
                    itemHoverStyle: {
                        color: "#FFF",
                    },
                    itemHiddenStyle: {
                        color: "#606063",
                    },
                    title: {
                        style: {
                            color: "#C0C0C0",
                        },
                    },
                },
                credits: {
                    style: {
                        color: "#666",
                    },
                },
                labels: {
                    style: {
                        color: "#707073",
                    },
                },
                drilldown: {
                    activeAxisLabelStyle: {
                        color: "#F0F0F3",
                    },
                    activeDataLabelStyle: {
                        color: "#F0F0F3",
                    },
                },
                navigation: {
                    buttonOptions: {
                        symbolStroke: "#DDDDDD",
                        theme: {
                            fill: "#505053",
                        },
                    },
                },
                // scroll charts
                rangeSelector: {
                    buttonTheme: {
                        fill: "#505053",
                        stroke: "#000000",
                        style: {
                            color: "#CCC",
                        },
                        states: {
                            hover: {
                                fill: "#707073",
                                stroke: "#000000",
                                style: {
                                    color: "white",
                                },
                            },
                            select: {
                                fill: "#000003",
                                stroke: "#000000",
                                style: {
                                    color: "white",
                                },
                            },
                        },
                    },
                    inputBoxBorderColor: "#505053",
                    inputStyle: {
                        backgroundColor: "#333",
                        color: "silver",
                    },
                    labelStyle: {
                        color: "silver",
                    },
                },
                navigator: {
                    handles: {
                        backgroundColor: "#666",
                        borderColor: "#AAA",
                    },
                    outlineColor: "#CCC",
                    maskFill: "rgba(255,255,255,0.1)",
                    series: {
                        color: "#7798BF",
                        lineColor: "#A6C7ED",
                    },
                    xAxis: {
                        gridLineColor: "#505053",
                    },
                },
                scrollbar: {
                    barBackgroundColor: "#808083",
                    barBorderColor: "#808083",
                    buttonArrowColor: "#CCC",
                    buttonBackgroundColor: "#606063",
                    buttonBorderColor: "#606063",
                    rifleColor: "#FFF",
                    trackBackgroundColor: "#404043",
                    trackBorderColor: "#404043",
                },
            };
            // Apply the theme
            Highcharts.setOptions(Highcharts.theme);
        },
        formatCurrency(value) {
            return (value / 100).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
        formatOtherCurrency(value, currency) {
            return (value / 100).toLocaleString("pt-br", {
                style: "currency",
                currency: currency.toUpperCase(),
            });
        },
        ...mapActions(Store, ["fetchAuthUser", "cancelPendingRequests"]),
    },
    watch: {
        dark(dark) {
            if (dark) {
                document.documentElement.classList.add("dark");
            } else {
                document.documentElement.classList.remove("dark");
            }
            localStorage.setItem("dark", dark);
        },
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title
                    ? to.meta.title + " - Ticto"
                    : "Ticto";
            },
        },
    },
};
</script>

<style scoped>
.logo {
    height: 6em;
    padding: 1.5em;
    will-change: filter;
}
.logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
    filter: drop-shadow(0 0 2em #42b883aa);
}

:deep(.router-view) {
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
}

:deep(.container) {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
</style>
