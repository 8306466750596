<template>
    <div
        class="overflow-hidden rounded-xl bg-slate-50 p-3 dark:bg-slate-800/25"
    >
        <div class="w-full">
            <div class="mx-auto w-full rounded-2xl">
                <div>
                    <h3
                        class="mt-1 text-lg font-medium leading-6 dark:text-white"
                    >
                        Detalhes
                    </h3>
                </div>
                <div class="mt-3 border-t dark:border-slate-900">
                    <dl class="divide-y dark:divide-slate-900">
                        <div
                            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                        >
                            <dt class="text-sm font-medium text-gray-500">
                                Tipo de Conta
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <span class="flex-grow">{{
                                    account_type
                                }}</span>
                            </dd>
                        </div>
                        <div
                            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                        >
                            <dt class="text-sm font-medium text-gray-500">
                                Nome
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <span class="flex-grow">{{ user.name }}</span>
                            </dd>
                        </div>
                        <div
                            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                        >
                            <dt class="text-sm font-medium text-gray-500">
                                Email
                            </dt>
                            <dd
                                class="mt-1 flex items-center text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <span class="flex-grow">{{ user.email }}</span>
                                <button
                                    type="button"
                                    class="rounded-md bg-indigo-600 p-1.5 text-white transition duration-150 ease-in-out hover:bg-indigo-500"
                                    v-if="user?.user_email_logs?.length"
                                    @click="openModalLogs = true"
                                >
                                    Ver Logs ({{
                                        user?.user_email_logs?.length
                                    }})
                                </button>
                            </dd>
                        </div>
                        <div
                            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                        >
                            <dt class="text-sm font-medium text-gray-500">
                                {{ user.type === "company" ? "CNPJ" : "CPF" }}
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <span class="flex-grow">{{
                                    user.type === "company"
                                        ? formatCnpj(user.cnpj)
                                        : formatCpf(user.cpf)
                                }}</span>
                            </dd>
                        </div>
                        <div
                            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                            v-if="user.about"
                        >
                            <dt class="text-sm font-medium text-gray-500">
                                About
                            </dt>
                            <dd
                                class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                            >
                                <span class="flex-grow">{{ user.about }}</span>
                            </dd>
                        </div>
                        <div
                            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                            v-if="user.website"
                        >
                            <dt class="text-sm font-medium text-gray-500">
                                Website
                            </dt>
                            <dd
                                class="mt-1 flex text-sm text-gray-900 sm:col-span-2 sm:mt-0"
                            >
                                <span class="flex-grow">{{
                                    user.website
                                }}</span>
                            </dd>
                        </div>
                        <template
                            v-for="address in user.addresses"
                            :key="address.id"
                        >
                            <div
                                class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                            >
                                <dt class="text-sm font-medium text-gray-500">
                                    Endereço
                                </dt>
                                <dd
                                    class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                                >
                                    <span class="flex-grow">
                                        {{ address.street }}, n°
                                        {{ address.street_number }} -
                                        {{ address.neighborhood }} -
                                        {{ address.city }}/{{ address.state }} -
                                        {{ address.zip_code }}
                                    </span>
                                </dd>
                            </div>
                        </template>
                        <template v-for="phone in user.phones" :key="phone.id">
                            <div
                                class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                            >
                                <dt class="text-sm font-medium text-gray-500">
                                    Telefone
                                </dt>
                                <dd
                                    class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                                >
                                    <span class="flex-grow">
                                        +{{ phone.ddi }} ({{ phone.ddd }})
                                        {{ formatPhone(phone.number) }}
                                    </span>
                                </dd>
                            </div>
                        </template>

                        <div
                            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                        >
                            <dt class="text-sm font-medium text-gray-500">
                                Gerente de Conta
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <span class="flex-grow">{{
                                    account_manager
                                }}</span>
                            </dd>
                        </div>
                        <div
                            class="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5"
                        >
                            <dt class="text-sm font-medium text-gray-500">
                                Parâmetros UTM Utilizados na URL no Cadastro
                            </dt>
                            <dd
                                class="mt-1 flex text-sm dark:text-white sm:col-span-2 sm:mt-0"
                            >
                                <span class="flex-grow">{{
                                    query_params_created
                                }}</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    </div>

    <div
        class="mt-4 overflow-hidden rounded-xl bg-slate-50 p-3 dark:bg-slate-800/25"
    >
        <div class="w-full">
            <div class="mx-auto w-full rounded-2xl">
                <div>
                    <h3
                        class="mb-4 mt-1 text-lg font-medium leading-6 dark:text-white"
                    >
                        Selo de Verificado
                    </h3>
                </div>

                <div class="flex flex-col px-4 pb-2 pt-4 text-sm text-gray-500">
                    <form-toggle
                        v-model="is_verified"
                        :loading="loading"
                        label="Habilitar selo?"
                    ></form-toggle>
                </div>
            </div>

            <div class="mx-auto mt-5 w-full rounded-2xl">
                <div>
                    <h3
                        class="mb-4 mt-1 text-lg font-medium leading-6 dark:text-white"
                    >
                        Configurações do Produtor
                    </h3>
                </div>

                <div class="flex flex-col px-4 pb-2 pt-4 text-sm text-gray-500">
                    <form-toggle
                        v-model="allow_integral_commission"
                        :loading="loading"
                        label="Habilitar afiliação com 100% de comissão?"
                    ></form-toggle>
                </div>
            </div>

            <submit-button
                @click.prevent="submit"
                :disabled="loading"
                type="submit"
                :loading="loading"
            >
                Salvar
            </submit-button>
        </div>
    </div>
    <modal :open="openModalLogs" @close="openModalLogs = false" size="4xl">
        <table-component
            :loading="false"
            :headers="['Email', 'Alterado por:', 'Motivo', 'Data de Alteração']"
        >
            <tr v-for="log in user?.user_email_logs" :key="log.id">
                <td class="table-cell">
                    <p>de: {{ log.old_email }}</p>
                    <p>para: {{ log.new_email }}</p>
                </td>
                <td class="table-cell">
                    <p>{{ log.changed_by_user.name }}</p>
                    <p>{{ log.changed_by_user.email }}</p>
                </td>
                <td class="table-cell">{{ log.reason }}</td>
                <td class="table-cell">{{ formatDate(log.created_at) }}</td>
            </tr>
        </table-component>
    </modal>
</template>

<script>
import { PaperClipIcon } from "@heroicons/vue/24/outline";
import CostActions from "../../../components/costs/CostActions.vue";
import FormToggle from "../../../components/forms/FormToggle.vue";
import Modal from "../../../components/layouts/Modal.vue";
import TableComponent from "../../../components/layouts/table/TableComponent.vue";
import HelperMixin from "../../../mixins/HelperMixin.vue";
import SubmitButton from "../../../components/forms/SubmitButton.vue";
export default {
    props: ["user"],
    components: {
        CostActions,
        TableComponent,
        Modal,
        PaperClipIcon,
        FormToggle,
        SubmitButton,
    },
    mixins: [HelperMixin],

    data() {
        return {
            openModalLogs: false,
            loading: false,
            is_verified: false,
            phones: [],
            addresses: [],
            queryParams: null,
            userEmailLogs: [],
            allow_integral_commission: false,
            account_type: "",
            account_manager: "",
            query_params_created: "",
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.loading = true;
            axios
                .get("/api/user/" + this.user.id)
                .then(({ data }) => {
                    console.info(data);
                    this.is_verified = data.setting?.is_verified;
                    this.phones = data.phones;
                    this.addresses = data.addresses;
                    this.userEmailLogs = data.user_email_logs;
                    this.allow_integral_commission =
                        data.setting?.allow_integral_commission;
                    this.account_type = data.account_type;
                    this.account_manager = data.account_manager;
                    this.query_params_created = data.query_params_created;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        submit() {
            this.loading = true;
            axios
                .put("/api/user/" + this.user.id + "/isVerified", {
                    is_verified: this.is_verified,
                    allow_integral_commission: this.allow_integral_commission,
                })
                .then(({ data }) => {
                    this.loading = false;
                })
                .catch(({ response }) => {
                    this.loading = false;
                    this.is_producer = !this.is_producer;
                    this.$notify(
                        {
                            group: "error",
                            title: "Error",
                            text: response.data.message,
                        },
                        4000,
                    );
                });
        },
    },
};
</script>
