<template>
    <form class="space-y-4 md:space-y-3" @submit.prevent="submit">
        <div class="grid grid-cols-12 gap-6">
            <div class="col-span-6">
                <label class="text-white" for="banner_title"
                    >Título do Banner</label
                >
                <input
                    v-model="form.title"
                    id="banner_title"
                    type="text"
                    required
                    name="title"
                    class="text-input mt-1 block w-full"
                />
            </div>
            <div class="col-span-6">
                <label class="text-white" for="redirect_link"
                    >Link de Redirecionamento</label
                >
                <input
                    v-model="form.redirect_link"
                    id="redirect_link"
                    type="url"
                    name="redirect_link"
                    class="text-input mt-1 block w-full"
                />
            </div>
            <div class="col-span-6">
                <label class="text-white" for="begin_at"
                    >Data e Hora de Início</label
                >
                <input
                    v-model="form.begin_at"
                    id="begin_at"
                    type="datetime-local"
                    name="begin_at"
                    class="text-input mt-1 block w-full"
                />
            </div>
            <div class="col-span-6">
                <label class="text-white" for="end_at"
                    >Data e Hora de Fim</label
                >
                <input
                    v-model="form.end_at"
                    id="end_at"
                    type="datetime-local"
                    name="end_at"
                    class="text-input mt-1 block w-full"
                />
            </div>
            <div class="col-span-1 sm:col-span-1 lg:col-span-1">
                <form-toggle
                    v-model="form.is_active"
                    :loading="loading"
                    labelColor="text-white"
                    label="Ativo?"
                />
            </div>
            <div class="col-span-12">
                <label class="text-white" for="desktop_image_url"
                    >Banner Desktop (4774 x 633)</label
                >
                <div
                    v-if="
                        form.desktop_image_url &&
                        !form.desktop_image_url?.includes('tmp')
                    "
                >
                    <img :src="form.desktop_image_url" :alt="form.title" />
                    <button
                        @click.prevent="handleDeleteImage('desktop')"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="mt-2 inline-flex items-center justify-center rounded-md bg-red-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-red-400"
                    >
                        <TrashIcon
                            class="h-4 w-4 cursor-pointer disabled:cursor-not-allowed"
                            :disabled="loading"
                            @click="selectedUser = false"
                            aria-hidden="true"
                        />
                        Remover imagem
                    </button>
                </div>
                <file-pond
                    v-else="form.desktop_image_url"
                    name="desktop_image_url"
                    ref="pondDesktop"
                    class-name="my-pond mt-1 block w-full"
                    label-idle="Arraste ou clique para adicionar arquivos para o banner desktop..."
                    :allow-multiple="false"
                    accepted-file-types="image/png"
                    :server="{
                        process: (
                            fieldName,
                            file,
                            metadata,
                            load,
                            error,
                            progress,
                            abort,
                        ) => {
                            this.uploadFile(
                                fieldName,
                                file,
                                metadata,
                                load,
                                error,
                                progress,
                                abort,
                                'desktop',
                                4774,
                                633,
                            );
                        },
                    }"
                />
            </div>
            <div class="col-span-12">
                <label class="text-white" for="mobile_image_url"
                    >Banner Mobile (646 x 180)</label
                >
                <div
                    v-if="
                        form.mobile_image_url &&
                        !form.mobile_image_url?.includes('tmp')
                    "
                >
                    <img :src="form.mobile_image_url" :alt="form.title" />
                    <button
                        @click.prevent="handleDeleteImage('mobile')"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="mt-2 inline-flex items-center justify-center rounded-md bg-red-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-red-400"
                    >
                        <TrashIcon
                            class="h-4 w-4 cursor-pointer disabled:cursor-not-allowed"
                            :disabled="loading"
                            @click="selectedUser = false"
                            aria-hidden="true"
                        />
                        Remover imagem
                    </button>
                </div>
                <file-pond
                    v-else="form.mobile_image_url"
                    name="mobile_image_url"
                    ref="pondMobile"
                    class-name="my-pond mt-1 block w-full"
                    label-idle="Arraste ou clique para adicionar arquivos para o banner mobile..."
                    :allow-multiple="false"
                    accepted-file-types="image/png"
                    :server="{
                        process: (
                            fieldName,
                            file,
                            metadata,
                            load,
                            error,
                            progress,
                            abort,
                        ) => {
                            this.uploadFile(
                                fieldName,
                                file,
                                metadata,
                                load,
                                error,
                                progress,
                                abort,
                                'mobile',
                                646,
                                180,
                            );
                        },
                    }"
                />
            </div>
        </div>
        <submit-button :loading="loading" class="ml-auto">Salvar</submit-button>
    </form>
</template>

<script>
import { vMaska } from "maska";
import SubmitButton from "../forms/SubmitButton.vue";
import FormToggle from "../forms/FormToggle.vue";
import { useToast } from "vue-toastification";
import { QuillEditor } from "@vueup/vue-quill";
import { TrashIcon } from "@heroicons/vue/24/outline";
import vueFilePond from "vue-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

const FilePond = vueFilePond(FilePondPluginImagePreview);

export default {
    props: { banner: { type: Object, default: null } },
    directives: { maska: vMaska },
    components: { SubmitButton, FormToggle, QuillEditor, TrashIcon, FilePond },
    emits: ["fetch"],
    data() {
        return {
            form: {
                title: "",
                redirect_link: "",
                begin_at: "",
                end_at: "",
                is_active: false,
                desktop_image_url: null,
                mobile_image_url: null,
            },
            loading: false,
        };
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    mounted() {
        if (this.banner) {
            this.form = {
                title: this.banner.title,
                icon: this.banner.icon,
                redirect_link: this.banner.redirect_link,
                is_active: this.banner.is_active,
                begin_at: this.formatDate(this.banner.begin_at),
                end_at: this.formatDate(this.banner.end_at),
                desktop_image_url: this.banner.desktop_image_url,
                mobile_image_url: this.banner.mobile_image_url,
            };
        }
    },

    methods: {
        formatDate(date) {
            const [day, month, yearAndTime] = date.split("/");
            const [year, time] = yearAndTime.split(" ");
            const [hours, minutes] = time.split(":");
            return `${year}-${month}-${day}T${hours}:${minutes}`;
        },
        async submit() {
            if (this.form.title === "") {
                this.toast.error("O título é obrigatório.");
                return;
            }

            if (this.form.redirect_link === "") {
                this.toast.error("O link de redirecionamento é obrigatório.");
                return;
            }

            if (this.form.begin_at === "") {
                this.toast.error("A data de início é obrigatória.");
                return;
            }

            if (this.form.end_at === "") {
                this.toast.error("A data de fim é obrigatória.");
                return;
            }

            if (this.form.desktop_image_url === null) {
                this.toast.error("A imagem do banner desktop é obrigatória.");
                return;
            }

            if (this.form.mobile_image_url === null) {
                this.toast.error("A imagem do banner mobile é obrigatória.");
                return;
            }

            this.loading = true;

            let promise = this.banner
                ? axios.put(`/api/banners/${this.banner.id}`, this.form)
                : axios.post("/api/banners", this.form);

            promise
                .then((response) => {
                    this.toast.success(
                        this.banner
                            ? "Banner editado com sucesso."
                            : "Banner cadastrado com sucesso.",
                    );
                    this.loading = false;
                    this.$emit("fetch");
                })
                .catch((error) => {
                    this.toast.error(
                        error?.response?.data?.message || banner
                            ? "Erro ao editar o banner."
                            : "Erro ao cadastrar o banner.",
                    );
                    this.loading = false;
                });
        },
        handleDeleteImage(type) {
            if (type === "desktop") {
                this.form.desktop_image_url = null;
            } else if (type === "mobile") {
                this.form.mobile_image_url = null;
            }
        },
        async store(file, options = null) {
            const response = await axios.post(
                "/vapor/signed-storage-url",
                {
                    bucket: options.bucket || "",
                    content_type: options.contentType || file.type,
                    expires: options.expires || "",
                    visibility: "public-read",
                    ...options.data,
                },
                {
                    // baseURL: options.baseURL || null,
                    ...options.options,
                },
            );

            if (response.status !== 200 && response.status !== 201) {
                throw new Error("Erro ao fazer upload.");
            }

            let headers = response.data.headers;

            if ("Host" in headers) {
                delete headers.Host;
            }

            if (typeof options.progress === "undefined") {
                options.progress = () => {};
            }

            const cancelToken = options.cancelToken || "";

            await axios.put(response.data.url, file, {
                cancelToken: cancelToken,
                headers: headers,
                withCredentials: false,
                onUploadProgress: (progressEvent) => {
                    options.progress(
                        progressEvent.loaded / progressEvent.total,
                    );
                },
            });

            response.data.extension = file.name.split(".").pop();

            return response.data;
        },
        uploadFile(
            fieldName,
            file,
            metadata,
            load,
            error,
            filePondProgress,
            abort,
            type,
            expectedWidth,
            expectedHeight,
        ) {
            if (file.type !== "image/png") {
                this.toast.error("A imagem deve ser do tipo PNG.");
                abort();
                this.handleDeleteImage(type);
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    if (
                        img.width !== expectedWidth ||
                        img.height !== expectedHeight
                    ) {
                        this.toast.error(
                            `A imagem deve ter as dimensões ${expectedWidth}x${expectedHeight}.`,
                        );
                        abort();
                        this.handleDeleteImage(type);
                        return;
                    }

                    this.store(file, {
                        progress: async (progress) => {
                            filePondProgress(Math.round(progress * 100));
                        },
                        abort,
                    })
                        .then((response) => {
                            load(response);
                            if (type === "desktop") {
                                this.form.desktop_image_url = response?.key;
                            } else if (type === "mobile") {
                                this.form.mobile_image_url = response?.key;
                            }
                        })
                        .catch((error) => {
                            abort();
                            if (type === "desktop") {
                                this.form.desktop_image_url = null;
                            } else if (type === "mobile") {
                                this.form.mobile_image_url = null;
                            }
                        });
                };
                img.onerror = () => {
                    this.toast.error("Erro ao carregar a imagem.");
                    abort();
                    this.handleDeleteImage(type);
                };
                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        },
    },
};
</script>
