<template>
    <div class="mb-4" v-if="showMidDayCutoff || urgentCashoutCount > 0">
        <div class="rounded-lg" :class="midDayPassed ? 'bg-red-50 dark:bg-red-900/20' : 'bg-indigo-50 dark:bg-indigo-900/20'">
            <div class="flex items-center justify-between p-4">
                <div class="flex">
                    <ExclamationTriangleIcon 
                        class="h-5 w-5" 
                        :class="midDayPassed ? 'text-red-500' : 'text-indigo-500'" 
                        aria-hidden="true" 
                    />
                    <div class="ml-3 text-sm font-medium" :class="midDayPassed ? 'text-red-800 dark:text-red-300' : 'text-indigo-800 dark:text-indigo-300'">
                        <span class="font-bold">{{ midDayPassed ? 'Prazo encerrado!' : 'Atenção!' }}</span> 
                        <span v-if="!midDayPassed">
                            Restam <span class="font-bold">{{ remainingTimeFormatted }}</span> para processar os saques de hoje.
                        </span>
                        <span v-else>
                            O prazo das 12h já passou, mas ainda há saques pendentes.
                        </span>
                        <span class="block mt-1">
                            Existem <span class="font-bold">{{ urgentCashoutCount }}</span> saques pendentes que 
                            {{ midDayPassed ? 'deveriam ter sido processados' : 'precisam ser processados' }} até meio-dia.
                        </span>
                        
                        <!-- Debug info -->
                        <div v-if="showDebugInfo" class="mt-2 text-xs p-1 rounded bg-indigo-100 dark:bg-indigo-800 dark:text-indigo-200">
                            <div><strong>Debug:</strong> Carregando contagem: {{ loadingUrgentCount }}</div>
                            <div>Contagem de urgentes: {{ urgentCashoutCount }}</div>
                            <div>Total na paginação: {{ pagination.meta.total }}</div>
                            <div>Current page: {{ pagination.meta.current_page }}</div>
                            <div>Per page: {{ pagination.meta.per_page }}</div>
                            <div class="mt-1"><strong>Dados do servidor:</strong></div>
                            <div v-if="debugServerData">
                                <div>Data atual: {{ debugServerData.now }}</div>
                                <div>Dia da semana: {{ debugServerData.day_of_week }} ({{ getDayName(debugServerData.day_of_week) }})</div>
                                <div>É fim de semana: {{ debugServerData.is_weekend ? 'Sim' : 'Não' }}</div>
                            </div>
                            <button @click="fetchUrgentCount" class="mt-1 px-2 py-1 bg-indigo-200 dark:bg-indigo-700 rounded">Refresh</button>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-2">
                    <button 
                        class="rounded-md p-2 text-sm font-medium"
                        :class="midDayPassed ? 
                            'bg-red-100 text-red-800 hover:bg-red-200 dark:bg-red-800 dark:text-red-200 dark:hover:bg-red-700' : 
                            'bg-indigo-100 text-indigo-800 hover:bg-indigo-200 dark:bg-indigo-800 dark:text-indigo-200 dark:hover:bg-indigo-700'"
                        @click="filterUrgentReviews"
                    >
                        {{ isFilteringUrgent ? 'Mostrar todos' : 'Mostrar apenas urgentes' }}
                    </button>
                    <button 
                        class="rounded-md bg-gray-100 p-1 text-xs font-medium text-gray-800 hover:bg-gray-200 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700"
                        @click="toggleDebugInfo"
                    >
                        {{ showDebugInfo ? 'Esconder debug' : 'Mostrar debug' }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <filters
        v-model="filters"
        @fetch="
            pagination.meta.current_page = 1;
            reviews = [];
            fetch();
        "
        status-filter
        :default-status="['pending']"
        :statuses="[
            { label: 'Solicitado', value: 'pending' },
            { label: 'Aprovado', value: 'approved' },
            { label: 'Reprovado', value: 'rejected' },
        ]"
    >
        <text-input
            placeholder="Min Approved"
            v-model="minApproved"
            type="number"
        />

        <template #actions>
            <div
                class="flex items-center rounded bg-indigo-700 p-2 text-white shadow"
            >
                {{ pagination.meta.total }}
            </div>
            <button 
                class="ml-2 flex items-center gap-1 rounded-md bg-indigo-600 p-2 text-sm font-medium text-white hover:bg-indigo-700"
                @click="filterUrgentReviews"
                v-tooltip="'Saques que precisam ser processados até 12h de hoje'"
            >
                <ClockIcon class="h-5 w-5" />
                {{ isFilteringUrgent ? 'Todos' : 'Até 12h' }}
            </button>
        </template>
    </filters>

    <div class="space-y-1">
        <empty-state v-if="!loading && reviews.length < 1" />
        <div class="flex flex-col" v-for="review in reviews">
            <div
                :key="review.id"
                class="flex items-center justify-between rounded-lg bg-white px-3 py-4 shadow dark:bg-slate-800"
                :class="{'border-l-4 border-indigo-500': isSameDayUrgent(review)}"
            >
                <div class="flex items-center">
                    <img
                        v-tooltip="
                            review.cashout.user.name +
                            ' - ' +
                            review.cashout.user.email
                        "
                        @click.prevent="openedReview = review.id"
                        class="h-8 w-8 rounded-full hover:cursor-pointer hover:ring-2"
                        :src="
                            review.cashout.user.avatar ||
                            'https://api.dicebear.com/7.x/initials/svg?backgroundColor=c0aede&seed=' +
                                review.cashout.user.name
                        "
                    />
                    <div class="ml-2 flex flex-col">
                        <div class="flex space-x-2">
                            <a
                                href="#"
                                @click.prevent="openedReview = review.id"
                                class="font-medium text-indigo-600 hover:text-indigo-800"
                            >
                                {{ review.cashout.user.email }}
                            </a>
                            <a
                                v-clipboard:copy="review.cashout.user.email"
                                v-tooltip="'Copiar email'"
                                class="cursor-pointer text-indigo-500 text-xs flex"
                            >
                                <EnvelopeIcon class="h-3 w-3 focus-visible:ring-0" aria-hidden="true" />
                            </a>
                        </div>
                        <p class="text-sm text-gray-600 dark:text-gray-300">
                            {{ review.cashout.user.name }} -
                            <span v-tooltip="review.created_at">
                                {{ review.human_created_at }}
                            </span>
                            <span v-if="isSameDayUrgent(review)" class="ml-1 text-xs font-bold text-indigo-500 dark:text-indigo-400">
                                (Até 12h)
                            </span>
                        </p>
                    </div>
                    <cashout-status
                        class="ml-3"
                        :status="review.cashout.status"
                        size="sm"
                    />
                    <div
                        v-if="review.seller"
                        class="text-lg font-bold"
                        v-tooltip="'Score'"
                        :class="{
                            'text-red-500': review.seller.score <= 25,
                            'text-yellow-500':
                                review.seller.score > 25 &&
                                review.seller.score <= 50,
                            'text-green-500': review.seller.score > 50,
                        }"
                    >
                        {{ review.seller.score }}
                    </div>
                </div>
                <div class="flex items-center">
                    <div
                        v-if="review.reviews && review.reviews.warning"
                        class="mr-2 flex items-center text-yellow-500"
                        v-tooltip="'Reviews Atenção'"
                    >
                        {{ review.reviews.warning }}
                        <ExclamationTriangleIcon
                            class="ml-1 h-6 w-6 focus-visible:ring-0"
                            aria-hidden="true"
                        />
                    </div>
                    <div
                        v-if="review.reviews && review.reviews.approved"
                        class="mr-2 flex items-center text-green-500"
                        v-tooltip="'Reviews Aprovados'"
                    >
                        {{ review.reviews.approved }}
                        <CheckCircleIcon
                            class="ml-1 h-6 w-6 focus-visible:ring-0"
                            aria-hidden="true"
                        />
                    </div>
                    <div
                        v-if="review.reviews && review.reviews.rejected"
                        class="mr-2 flex items-center text-red-500"
                        v-tooltip="'Reviews Reprovados'"
                    >
                        {{ review.reviews.rejected }}
                        <NoSymbolIcon
                            class="ml-1 h-6 w-6 focus-visible:ring-0"
                            aria-hidden="true"
                        />
                    </div>
                    <div class="relative">
                        <img
                            v-tooltip="
                                review.user.name + ' - ' + review.user.email
                            "
                            class="h-8 w-8 rounded-full"
                            :src="review.user.avatar || 'default-avatar-url'"
                        />
                    </div>
                    <div class="ml-4 flex flex-col">
                        <p
                            class="text-sm font-medium text-gray-900 dark:text-white"
                        >
                            {{ $root.formatCurrency(review.cashout.net_value) }}
                        </p>
                        <p
                            :class="statusClass(review.status)"
                            class="text-xs font-medium"
                        >
                            {{ statusName(review.status) }}
                        </p>
                        <div class="mt-2 flex flex-wrap gap-1">
                            <span
                                v-for="(commission, type) in review.cashout.commission_types"
                                :key="type"
                                class="rounded-full bg-blue-100 px-2 py-1 text-xs font-semibold text-blue-800"
                                v-tooltip="$root.formatCurrency(commission.amount)"
                            >
                                {{ formatCommissionType(type) }}: {{ commission.total }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <review-cashout
                :cashout="review.cashout"
                :review="review"
                :payable-details="payableDetails[review.cashout.id]"
                @fetch="
                    if (isSameDayUrgent(review)) {
                        urgentCashoutCount = Math.max(0, urgentCashoutCount - 1);
                    }
                    reviews = [];
                    fetch();
                "
            />
        </div>
    </div>
</template>

<script>
import CommissionType from '../../mixins/CommissionType.vue'
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import { CheckIcon, ClockIcon } from "@heroicons/vue/20/solid";
import ReviewModal from "../../components/cashouts/reviews/ReviewModal.vue";
import Filters from "../../components/layouts/Filters.vue";
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
    NoSymbolIcon,
    ExclamationTriangleIcon,
    EnvelopeIcon
} from "@heroicons/vue/24/outline";
import CashoutStatus from "../../components/layouts/CashoutStatus.vue";
import SubmitButton from "../../components/forms/SubmitButton.vue";
import EmptyState from "../../components/layouts/EmptyState.vue";
import TextInput from "../../components/forms/TextInput.vue";
import ReviewCashout from "../../components/cashouts/reviews/ReviewCashout.vue";

export default {
    name: 'ReviewsCockpit',
    components: {
        ReviewCashout,
        TextInput,
        EmptyState,
        ExclamationTriangleIcon,
        SubmitButton,
        CashoutStatus,
        ExclamationCircleIcon,
        Filters,
        ReviewModal,
        CheckIcon,
        ClockIcon,
        TableComponent,
        CheckCircleIcon,
        NoSymbolIcon,
        EnvelopeIcon
    },
    data() {
        return {
            loading: false,
            loadingUrgentCount: false,
            urgentCashoutCount: 0,
            showDebugInfo: false,
            debugServerData: null,
            reviews: [],
            openedReview: null,
            filters: {},
            minApproved: "",
            isFilteringUrgent: false,
            remainingTime: 0,
            midDayPassed: false,
            timerInterval: null,
            pagination: {   
                meta: {
                    current_page: 1,
                    last_page: 10,
                    page: 1,
                    per_page: 10,
                    total: 0,
                },
            },
            payableDetails: {},
        };
    },

    computed: {
        remainingTimeFormatted() {
            if (this.midDayPassed) {
                return "00:00:00";
            }
            
            const hours = Math.floor(this.remainingTime / 3600);
            const minutes = Math.floor((this.remainingTime % 3600) / 60);
            const seconds = this.remainingTime % 60;
            
            return [
                hours.toString().padStart(2, '0'),
                minutes.toString().padStart(2, '0'),
                seconds.toString().padStart(2, '0')
            ].join(':');
        },
        
        showMidDayCutoff() {
            const now = new Date();
            const day = now.getDay();
            return day >= 1 && day <= 5;
        }
    },

    mounted() {
        this.filters.status = ["pending"];
        this.startMidDayTimer();
        setTimeout(() => {
            this.fetch();
            this.fetchUrgentCount();
        }, 500);
    },
    
    beforeDestroy() {
        if (this.timerInterval) {
            clearInterval(this.timerInterval);
        }
    },

    methods: {
        getDayName(dayOfWeek) {
            const days = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
            return days[dayOfWeek] || '';
        },
        
        toggleDebugInfo() {
            this.showDebugInfo = !this.showDebugInfo;
        },
        
        startMidDayTimer() {
            let lastCountUpdate = 0; // Registrar o último tempo de atualização
            
            const updateTimer = () => {
                const now = new Date();
                const midDay = new Date(
                    now.getFullYear(),
                    now.getMonth(),
                    now.getDate(),
                    12, 0, 0
                );
                
                if (now >= midDay) {
                    this.midDayPassed = true;
                    this.remainingTime = 0;
                } else {
                    this.midDayPassed = false;
                    this.remainingTime = Math.floor((midDay - now) / 1000);
                }
                
                // Atualizar contagem no máximo a cada 60 segundos
                const currentTime = Math.floor(Date.now() / 1000);
                if ((this.midDayPassed || this.remainingTime < 1800) && 
                    (currentTime - lastCountUpdate > 60)) {
                    this.fetchUrgentCount();
                    lastCountUpdate = currentTime;
                }
            };
            
            updateTimer();
            this.timerInterval = setInterval(updateTimer, 1000);
        },

        fetch() {
            this.loading = true;

            let filters = this.filters;
            filters["filter[minApproved]"] = this.minApproved;
            
            if (this.isFilteringUrgent) {
                filters["filter[urgentMidDay]"] = true;
            }

            axios
                .get("/api/cashout/review", {
                    params: {
                        page: this.pagination.meta.current_page,
                        per_page: 1,
                        ...this.filters,
                    },
                })
                .then(({ data }) => {
                    this.reviews = data.data;
                    this.pagination = data;
                    this.fetchPayableDetails();
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        
        fetchUrgentCount() {
            this.loadingUrgentCount = true;
            
            axios
                .get("/api/cashout/review/count", {
                    params: {
                        "filter[status]": "pending"
                    },
                })
                .then(({ data }) => {
                    this.urgentCashoutCount = data.count || 0;
                    this.debugServerData = data.debug_date || null;
                    console.log('Contagem de saques urgentes:', this.urgentCashoutCount);
                    console.log('Debug data:', data);
                })
                .catch(error => {
                    console.error('Erro ao buscar contagem de saques urgentes:', error);
                    
                    if (this.isFilteringUrgent) {
                        this.urgentCashoutCount = this.pagination.meta.total;
                    }
                })
                .finally(() => {
                    this.loadingUrgentCount = false;
                });
        },
        
        filterUrgentReviews() {
            this.isFilteringUrgent = !this.isFilteringUrgent;
            this.pagination.meta.current_page = 1;
            this.reviews = [];
            this.fetch();
            
            setTimeout(() => this.fetchUrgentCount(), 500);
        },
        
        isSameDayUrgent(review) {
            const reviewDate = new Date(review.created_at);
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const yesterday = new Date(today);
            yesterday.setDate(yesterday.getDate() - 1);
            
            const reviewDay = reviewDate.getDay();
            const reviewHour = reviewDate.getHours();
            
            if (reviewDate >= today && reviewHour < 12) {
                return true;
            }
            
            if (reviewDate >= yesterday && reviewHour >= 12) {
                const yesterdayDay = yesterday.getDay();
                return yesterdayDay >= 1 && yesterdayDay <= 4;
            }
            
            if (reviewDate >= yesterday) {
                const dayOfWeek = reviewDate.getDay();
                
                if (dayOfWeek === 5 && reviewHour >= 12) {
                    return true;
                }
                
                if (dayOfWeek === 6 || dayOfWeek === 0) {
                    return true;
                }
            }
            
            return false;
        },

        fetchPayableDetails() {
            const promises = this.reviews.map(review => {
                if (!this.payableDetails[review.cashout.id]) {
                    return axios.get(`/api/cashout/${review.cashout.id}/payables/details`)
                        .then(({ data }) => {
                            this.payableDetails[review.cashout.id] = data;
                        })
                        .catch(error => {
                            console.error(`Erro ao buscar detalhes do cashout ${review.cashout.id}:`, error);
                        });
                }
                return Promise.resolve();
            });

            return Promise.all(promises);
        },

        statusName(name) {
            switch (name) {
                case "pending":
                    return "Solicitado";
                case "approved":
                    return "Aprovado";
                case "warning":
                    return "Atenção";
                case "rejected":
                    return "Reprovado";
            }
        },
        statusClass(status) {
            switch (status) {
                case "pending":
                case "warning":
                    return "text-yellow-500";
                case "approved":
                    return "text-green-500";
                case "rejected":
                    return "text-red-500";
            }
        },
    },
};
</script>
