<template>
    <div>
        <nav-tabs :tabs="routes" :exact="false" />
        <div>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import NavTabs from "../../components/layouts/NavTabs.vue";
import PermissionMixin from "../../mixins/PermissionMixin.vue";

export default {
    components: { NavTabs },
    mixins: [PermissionMixin],
    data() {
        return {
            routes: [
                { label: "Lista", route: "notices.list" },
                { label: "Banners", route: "notices.banners" },
            ],
        };
    },
};
</script>
