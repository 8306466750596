<template>
    <div class="flex flex-col gap-4">
        <Modal
            :open="showModal"
            title="Register Passkey"
            @close="showModal = false"
        >
            <div class="flex flex-col space-y-1">
                <input class="text-input" v-model="name" placeholder="Name" />
                <SubmitButton
                    :disabled="loading"
                    :loading="loading"
                    @click="registerPasskey"
                    class="w-full"
                >
                    Register
                </SubmitButton>
            </div>
        </Modal>

        <Modal
            :open="showDeleteModal"
            title="Excluir Passkey"
            @close="showDeleteModal = false"
        >
            <div class="flex flex-col space-y-4 dark:text-white">
                <p class="text-white">Tem certeza que deseja excluir esta passkey?</p>
                <div class="flex flex-col space-y-2">
                    <button 
                        @click="showDeleteModal = false"
                        class="w-full px-4 py-2 text-xs font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                    >
                        Cancelar
                    </button>
                    <button 
                        @click="confirmDelete"
                        class="w-full px-4 py-2 text-xs font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700"
                    >
                        Excluir
                    </button>
                </div>
            </div>
        </Modal>

        <div class="flex flex-row items-center gap-2">
            <div class="flex items-center gap-2">
                <KeyIcon class="w-6 h-6 text-gray-500" />
                <h3 class="text-lg font-bold dark:text-white">Passkeys</h3>
            </div>
            <SubmitButton
                class="ml-auto"
                :loading="loading"
                @click="showRegisterModal"
            >
                <PlusIcon class="w-5 h-5 mr-1" />
                Register
            </SubmitButton>
            <ArrowPathIcon
                class="ml-1 h-6 w-6 cursor-pointer text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
                :class="{ 'animate-spin': loading }"
                @click="fetch"
            />
        </div>

        <div class="flex flex-col gap-4">
            <EmptyState
                v-if="passkeys.length === 0"
                class="mb-4"
                title="Nenhuma passkey encontrada"
                description="Crie uma passkey para começar a usar o Passkeys"
            />
            <div
                v-for="passkey in passkeys"
                :key="passkey.id"
                class="flex flex-col p-4 dark:bg-slate-800/25 bg-white rounded-lg border dark:border-slate-700 border-gray-200 hover:shadow-md transition-all duration-200"
            >
                <div class="flex items-center justify-between">
                    <div class="flex items-center gap-4 flex-wrap">
                        <div class="flex items-center gap-3">
                            <FingerPrintIcon class="w-5 h-5 text-gray-500" />
                            <span class="font-medium text-gray-700 dark:text-white">{{ passkey.name }}</span>
                        </div>

                        <div v-if="passkey.device" class="flex items-center gap-4">
                            <div class="flex items-center gap-2">
                                <ComputerDesktopIcon class="w-4 h-4 shrink-0" />
                                <span class="text-sm text-gray-500 dark:text-gray-400">{{ passkey.device.platform }} {{ passkey.device.platform_version }}</span>
                            </div>
                            <div class="flex items-center gap-2">
                                <GlobeAltIcon class="w-4 h-4 shrink-0" />
                                <span class="text-sm text-gray-500 dark:text-gray-400">{{ passkey.device.browser }} {{ passkey.device.browser_version }}</span>
                            </div>
                            <div v-if="passkey.device.device_name" class="flex items-center gap-2">
                                <DevicePhoneMobileIcon class="w-4 h-4 shrink-0" />
                                <span class="text-sm text-gray-500 dark:text-gray-400">{{ passkey.device.device_name }}</span>
                            </div>
                            <div class="flex items-center gap-2">
                                <ClockIcon class="w-4 h-4 shrink-0" />
                                <span class="text-sm text-gray-500 dark:text-gray-400">{{ formatDate(passkey.created_at) }}</span>
                            </div>
                        </div>
                    </div>

                    <button 
                        @click="deletePasskey(passkey.id)"
                        class="p-2 text-gray-400 hover:text-red-500 rounded-full hover:bg-red-50 transition-colors duration-200 shrink-0"
                    >
                        <TrashIcon class="h-5 w-5" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SubmitButton from "../../forms/SubmitButton.vue";
import EmptyState from "../../layouts/EmptyState.vue";
import { 
    ArrowPathIcon,
    TrashIcon,
    KeyIcon,
    PlusIcon,
    FingerPrintIcon,
    ComputerDesktopIcon,
    GlobeAltIcon,
    DevicePhoneMobileIcon,
    ClockIcon,
    ShieldCheckIcon
} from "@heroicons/vue/24/outline";
import RegisterPasskey from "./RegisterPasskey.vue";
import { startRegistration } from "@simplewebauthn/browser";
import Modal from "../../layouts/Modal.vue";
import dayjs from 'dayjs';
import { ClientJS } from "clientjs";

export default {
    components: {
        SubmitButton,
        ArrowPathIcon,
        TrashIcon,
        KeyIcon,
        PlusIcon,
        FingerPrintIcon,
        ComputerDesktopIcon,
        GlobeAltIcon,
        DevicePhoneMobileIcon,
        ClockIcon,
        RegisterPasskey,
        Modal,
        ShieldCheckIcon,
        EmptyState
    },

    data() {
        return {
            showModal: false,
            showDeleteModal: false,
            name: "",
            loading: false,
            registerOptions: null,
            passkeys: [],
            passKeyToDelete: null,
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        formatDate(date) {
            return dayjs(date).format('DD/MM/YYYY HH:mm');
        },

        async registerPasskey() {
            this.loading = true;
            const options = await this.fetchRegisterOptions();
            console.log("registerOptions", options);
            const passkey = await startRegistration(options);
            console.log({ passkey });
            axios
                .post("/api/passkeys", {
                    passkey: JSON.stringify(passkey),
                    name: this.name,
                })
                .then(({ data }) => {
                    console.log("passkey", data);
                    this.fetch();
                    this.showModal = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        deletePasskey(id) {
            this.passKeyToDelete = id;
            this.showDeleteModal = true;
        },

        confirmDelete() {
            if (!this.passKeyToDelete) return;
            
            axios.delete(`/api/passkeys/${this.passKeyToDelete}`)
                .then(() => {
                    this.fetch();
                    this.showDeleteModal = false;
                    this.passKeyToDelete = null;
                });
        },

        async fetchRegisterOptions() {
            const { data } = await axios.get("/api/passkeys/register-options");
            this.registerOptions = data;
            return data;
        },

        async fetch() {
            this.loading = true;

            axios
                .get("/api/passkeys")
                .then(({ data }) => {
                    this.passkeys = data;
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getAuthenticatorType(authenticator) {
            if (!authenticator) return '';

            const details = authenticator.device_details || {};
            const flags = authenticator.flags || {};
            
            const features = [];
            
            if (details.name && details.vendor) {
                features.push(`${details.vendor} ${details.name}`);
            }
            
            if (flags.user_verified) {
                if (details.name === 'Face ID') {
                    features.push('Face ID');
                } else if (details.name === 'Touch ID') {
                    features.push('Touch ID');
                } else if (details.name === 'Android Face Unlock') {
                    features.push('Face Unlock');
                } else if (details.name === 'Android Fingerprint') {
                    features.push('Digital');
                } else if (details.name === 'Windows Hello') {
                    features.push('Windows Hello');
                } else {
                    features.push('Biometria');
                }
            }
            
            if (flags.backup_eligible) {
                features.push('Backup');
            }
            
            return features.join(' + ') || 'Dispositivo de Segurança';
        },

        getSuggestedName() {
            const client = new ClientJS();
            
            let deviceName = '';
            const os = client.getOS();
            const osVersion = client.getOSVersion();
            const device = client.getDevice();
            const deviceType = client.getDeviceType();
            const browser = client.getBrowser();
            const browser_version = client.getBrowserVersion();

            deviceName += os + osVersion + ` (${browser} ${browser_version})`;

            return deviceName;
        },

        showRegisterModal() {
            this.name = this.getSuggestedName();
            this.showModal = true;
        },
    },
};
</script>

<script setup>
import { ClientJS } from "clientjs";
</script>
