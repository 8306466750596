<template>
    <div class="container mx-auto dark:text-white">
        <!--        <div class="mb-4">-->
        <!--            <textarea-->
        <!--                v-model="prompt"-->
        <!--                placeholder="Digite sua pergunta aqui..."-->
        <!--                class="w-full rounded-lg border bg-gray-500 p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"-->
        <!--                rows="3"-->
        <!--            ></textarea>-->
        <!--        </div>-->

        <button
            :disabled="isLoading || reportExists"
            @click.prevent="generateResponse"
            class="flex w-full min-w-[200px] items-center justify-center rounded-lg border border-gray-300 bg-transparent px-4 py-2 py-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 disabled:cursor-not-allowed dark:border-gray-600 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        >
            <svg
                v-if="isLoading"
                class="-ml-1 mr-3 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
            >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                ></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
            </svg>
            Gerar Relatório
        </button>
        <div v-if="response" class="mt-4 rounded-lg bg-gray-900 p-4">
            <markdown-renderer
                class="mb-5 mr-auto rounded-lg shadow"
                :source="response"
            />
        </div>
        <div v-if="error" class="mt-4 rounded-lg bg-red-100 p-4 text-red-700">
            {{ error }}
        </div>
    </div>
</template>

<script>
import { events, stream } from 'fetch-event-stream';
import MarkdownRenderer from "../layouts/MarkdownRenderer.vue";
import SubmitButton from "../forms/SubmitButton.vue";

export default {
    components: { MarkdownRenderer, SubmitButton },
    props: {
        initialResponse: {
            type: String,
            default: ""
        },
        reportExists: {
            type: Boolean,
            default: false
        }
    },
    emits: ["update:modelValue", "generated"],
    data() {
        return {
            prompt: "",
            response: "",
            isLoading: false,
            error: null,
            chunks: [],
        };
    },
    mounted() {
        // Se já existe uma resposta inicial, usamos ela
        if (this.initialResponse) {
            this.response = this.initialResponse;
        }
    },
    watch: {
        // Observar mudanças na prop initialResponse
        initialResponse(newValue) {
            this.response = newValue;
        }
    },
    methods: {
        async generateResponse() {
            this.isLoading = true;
            this.response = "";
            this.error = null;

            try {
                const { data } = await axios.post(
                    "/api/ai/generate-report",
                    {
                        audit_id: this.$route.params.audit_id
                    }
                );
                const message = JSON.parse(data.message);
                console.log({ aner: message.message });
                this.response = message.message;
                this.$emit("update:modelValue", this.response);
                this.$emit("generated");
            } catch (error) {
                console.error("Error:", error);
                this.error =
                    "Ocorreu um erro ao gerar a resposta. Por favor, tente novamente.";
            } finally {
                this.isLoading = false;
            }
        },
    },
};
</script>
