<template>
    <transition-root as="template" :show="this.open">
        <dialog-headless
            as="div"
            class="fixed inset-0 z-10 overflow-y-auto"
            @close="closeModal()"
        >
            <div
                class="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>

                <span
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-3 text-left align-bottom shadow-xl transition-all dark:bg-gray-700 sm:my-8 sm:w-full sm:max-w-lg sm:align-middle"
                    >
                        <form @submit.prevent="blockUser()">
                            <div class="sm:flex sm:items-start">
                                <div class="text-center sm:text-left">
                                    <dialog-title
                                        as="h3"
                                        class="mb-4 border-b border-gray-600 pb-2 text-lg font-medium leading-6 dark:text-white"
                                    >
                                        Configurações Financeiras
                                    </dialog-title>
                                    <div class="mt-2">
                                        <div
                                            class="row flex"
                                            v-if="
                                                hasPermission('block.cashouts')
                                            "
                                        >
                                            <div class="col">
                                                <form-toggle
                                                    v-model="
                                                        settingData.is_cashout_locked
                                                    "
                                                    label="Bloquear saque"
                                                />
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col">
                                                <form-toggle
                                                    v-model="
                                                        settingData.is_refund_locked
                                                    "
                                                    label="Bloquear cancelamento de reembolso"
                                                />
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col">
                                                <form-toggle
                                                    v-model="
                                                        settingData.refund_providers_commission_from_producer
                                                    "
                                                    label="Reembolsar comissão do fornecedor do saldo do produtor"
                                                />
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col">
                                                <form-toggle
                                                    v-model="
                                                        settingData.allow_charge_platform_fee_upon_refund
                                                    "
                                                    label="Habilitar cobrança da taxa da plataforma no reembolso"
                                                />
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col">
                                                <form-toggle
                                                    v-model="
                                                        settingData.allow_cashout_under_review
                                                    "
                                                    label="Habilitar saque de saldo em análise"
                                                />
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col">
                                                <form-toggle
                                                    v-model="
                                                        settingData.allow_payment_in_installments
                                                    "
                                                    label="Habilitar recebimento no fluxo"
                                                />
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col">
                                                <form-toggle
                                                    v-model="
                                                        settingData.allow_payment_in_installments_for_provider
                                                    "
                                                    label="Habilitar recebimento no fluxo como fornecedor"
                                                />
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col">
                                                <form-toggle
                                                    v-model="
                                                        settingData.allow_new_international_checkout
                                                    "
                                                    label="Habilitar novo checkout internacional"
                                                />
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col">
                                                <form-toggle
                                                    v-model="
                                                        settingData.can_anticipate
                                                    "
                                                    label="Habilitar antecipação"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            class="row flex"
                                            v-if="settingData.can_anticipate"
                                        >
                                            <div class="w-full">
                                                <label
                                                    class="mb-2 hidden text-xs font-medium text-gray-900 dark:text-gray-300 md:block"
                                                    id="anticipation-version"
                                                >
                                                    Versão da Antecipação
                                                </label>
                                                <select
                                                    v-model="
                                                        settingData.anticipation_version
                                                    "
                                                    id="anticipation-version"
                                                    class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                >
                                                    <option value="v1">
                                                        Antecipação Limitada
                                                    </option>
                                                    <option value="v2">
                                                        Antecipação Completa
                                                    </option>
                                                </select>
                                                <div class="mt-1">
                                                    <span
                                                        class="mt-2 hidden text-xs font-medium text-gray-900 dark:text-gray-300 md:block"
                                                        v-if="
                                                            settingData.anticipation_version ===
                                                            'v1'
                                                        "
                                                    >
                                                        Na antecipação limitada
                                                        o limite é calculado
                                                        considerando apenas o
                                                        saldo pendente.
                                                    </span>
                                                    <span
                                                        class="my-2 hidden text-xs font-medium text-gray-900 dark:text-gray-300 md:block"
                                                        v-if="
                                                            settingData.anticipation_version ===
                                                            'v2'
                                                        "
                                                    >
                                                        Na antecipação completa
                                                        o limite é calculado
                                                        considerando o saldo
                                                        pendente e o valor
                                                        antecipado nos últimos
                                                        30 dias.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row flex">
                                            <div class="col mr-2 mt-2">
                                                <label
                                                    for="large-input"
                                                    class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                    >Taxa de antecipação (em
                                                    %)</label
                                                >
                                                <input
                                                    type="number"
                                                    min="1"
                                                    max="100"
                                                    required
                                                    v-model="
                                                        settingData.anticipation_fee
                                                    "
                                                    step="any"
                                                    class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                />
                                            </div>
                                            <div class="col ml-2 mt-2">
                                                <label
                                                    for="large-input"
                                                    class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                    >Limite de antecipação (em
                                                    %)</label
                                                >
                                                <input
                                                    type="number"
                                                    min="1"
                                                    max="100"
                                                    required
                                                    v-model="
                                                        settingData.anticipation_limit
                                                    "
                                                    step="any"
                                                    class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                class="my-5 flex-grow border-t border-gray-400"
                                            ></div>

                                            <div
                                                class="space-x-2 text-sm font-bold uppercase text-gray-900 dark:text-gray-300"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-dollar-sign"
                                                />
                                                Cobrança Fixa
                                            </div>
                                            <div class="row flex">
                                                <div class="col mr-2 mt-2">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Taxa fixa por transação
                                                        nacional (em R$)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        required
                                                        v-model="
                                                            settingData.platform_fixed_fee
                                                        "
                                                        max="10"
                                                        min="1"
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div class="col mr-2 mt-2">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Taxa fixa por transação
                                                        internacional (em
                                                        R$)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        required
                                                        v-model="
                                                            settingData.international_fixed_fee
                                                        "
                                                        max="10"
                                                        min="1"
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="my-5 flex-grow border-t border-gray-400"
                                            ></div>
                                        </div>
                                        <div>
                                            <div
                                                class="space-x-2 text-sm font-bold uppercase text-gray-900 dark:text-gray-300"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-credit-card"
                                                />
                                                Cartão de Crédito e Dois Cartões
                                            </div>
                                            <div class="row flex">
                                                <div class="col my-3 mr-2">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Tempo para saque (em
                                                        dias)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        required
                                                        v-model="
                                                            settingData.credit_card_cashout_days
                                                        "
                                                        max="365"
                                                        min="0"
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div class="col my-3 ml-2">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Taxa da plataforma (em
                                                        %)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        max="100"
                                                        required
                                                        v-model="
                                                            settingData.platform_fee_credit_card
                                                        "
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                            </div>
                                            <div class="mb-3 mr-2">
                                                <label
                                                    for="large-input"
                                                    class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                    >Taxa de juros por parcela
                                                    (em %)</label
                                                >
                                                <input
                                                    type="number"
                                                    min="1"
                                                    max="100"
                                                    required
                                                    v-model="
                                                        settingData.installment_fee_credit_card
                                                    "
                                                    step="any"
                                                    class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                />
                                            </div>
                                            <div
                                                class="my-5 flex-grow border-t border-gray-400"
                                            ></div>
                                        </div>
                                        <div>
                                            <div
                                                class="space-x-2 text-sm font-bold uppercase text-gray-900 dark:text-gray-300"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-barcode"
                                                />
                                                Boleto
                                            </div>
                                            <div class="row flex">
                                                <div class="col my-3 mr-2">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Tempo para saque (em
                                                        dias)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        required
                                                        v-model="
                                                            settingData.bank_slip_cashout_days
                                                        "
                                                        max="365"
                                                        min="0"
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div class="col my-3 ml-2">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Taxa da plataforma (em
                                                        %)
                                                    </label>
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        max="100"
                                                        required
                                                        v-model="
                                                            settingData.platform_fee_bank_slip
                                                        "
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                            </div>
                                            <div class="mb-3 mr-2">
                                                <label
                                                    for="large-input"
                                                    class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                    >Taxa de juros por parcela
                                                    (em %)</label
                                                >
                                                <input
                                                    type="number"
                                                    required
                                                    min="1"
                                                    max="100"
                                                    v-model="
                                                        settingData.installment_fee_bank_slip
                                                    "
                                                    step="any"
                                                    class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                />
                                            </div>
                                            <div
                                                class="my-5 flex-grow border-t border-gray-400"
                                            ></div>
                                        </div>
                                        <div>
                                            <div
                                                class="space-x-2 text-sm font-bold uppercase text-gray-900 dark:text-gray-300"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-brands fa-pix"
                                                />
                                                Pix
                                            </div>
                                            <div class="row flex">
                                                <div class="col mr-2 mt-3">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Tempo para saque (em
                                                        dias)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        required
                                                        v-model="
                                                            settingData.pix_cashout_days
                                                        "
                                                        max="365"
                                                        min="0"
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div class="col ml-2 mt-3">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Taxa da plataforma (em
                                                        %)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        max="100"
                                                        required
                                                        v-model="
                                                            settingData.platform_fee_pix
                                                        "
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="my-5 flex-grow border-t border-gray-400"
                                            ></div>
                                        </div>
                                        <div>
                                            <div
                                                class="space-x-2 text-sm font-bold uppercase text-gray-900 dark:text-gray-300"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-globe"
                                                />
                                                Pagamento Internacional
                                            </div>
                                            <div class="row flex">
                                                <div class="col mr-2 mt-3">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Tempo para saque (em
                                                        dias)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        required
                                                        v-model="
                                                            settingData.international_cashout_days
                                                        "
                                                        max="365"
                                                        min="0"
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div class="col ml-2 mt-3">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Taxa da plataforma (em
                                                        %)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        min="1"
                                                        max="100"
                                                        required
                                                        v-model="
                                                            settingData.international_platform_fee
                                                        "
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="my-5 flex-grow border-t border-gray-400"
                                            ></div>
                                        </div>
                                        <div>
                                            <div
                                                class="space-x-2 text-sm font-bold uppercase text-gray-900 dark:text-gray-300"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-star"
                                                />
                                                Programa de Indicações
                                            </div>
                                            <div class="row flex">
                                                <div class="col mr-2 mt-3">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                        >Liberação de saque para
                                                        indicações (%)</label
                                                    >
                                                    <input
                                                        type="number"
                                                        required
                                                        v-model="
                                                            settingData.referral_cashout_days
                                                        "
                                                        max="365"
                                                        min="1"
                                                        step="any"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                class="my-5 flex-grow border-t border-gray-400"
                                            ></div>
                                        </div>
                                        <div>
                                            <div
                                                class="space-x-2 text-sm font-bold uppercase text-gray-900 dark:text-gray-300"
                                            >
                                                <font-awesome-icon
                                                    icon="fa-solid fa-money-bill-transfer"
                                                />
                                                Cashback
                                            </div>
                                            <div class="row flex">
                                                <div class="col mr-2 mt-3">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Tipo de Taxa
                                                    </label>
                                                    <select
                                                        v-model="
                                                            settingData.cashback_type
                                                        "
                                                        id="tipo-cashback"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    >
                                                        <option value="fixed">
                                                            Fixo
                                                        </option>
                                                        <option
                                                            value="percentage"
                                                        >
                                                            Percentual
                                                        </option>
                                                    </select>
                                                </div>
                                                <div class="col ml-2 mt-3">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Valor da Taxa
                                                        {{
                                                            settingData.cashback_type ===
                                                            "percentage"
                                                                ? "(Em %)"
                                                                : "(Em R$)"
                                                        }}
                                                    </label>
                                                    <input
                                                        type="number"
                                                        required
                                                        v-model="
                                                            settingData.cashback_fee
                                                        "
                                                        step="any"
                                                        @input="
                                                            settingData.cashback_fee =
                                                                validateInputInterval(
                                                                    $event,
                                                                    0,
                                                                    settingData.cashback_type ===
                                                                        'percentage'
                                                                        ? 100
                                                                        : 10000,
                                                                )
                                                        "
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    />
                                                </div>
                                                <div class="col ml-2 mt-3">
                                                    <label
                                                        for="large-input"
                                                        class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                    >
                                                        Origem
                                                    </label>
                                                    <select
                                                        v-model="
                                                            settingData.cashback_origin
                                                        "
                                                        id="origem-cashback"
                                                        class="rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                                    >
                                                        <option
                                                            v-if="
                                                                settingData.cashback_type ===
                                                                'percentage'
                                                            "
                                                            value="platform"
                                                        >
                                                            Plataforma
                                                        </option>
                                                        <option
                                                            v-if="
                                                                settingData.cashback_type ===
                                                                'percentage'
                                                            "
                                                            value="interest"
                                                        >
                                                            Juros
                                                        </option>
                                                        <option
                                                            v-if="
                                                                settingData.cashback_type ===
                                                                'fixed'
                                                            "
                                                            value="all"
                                                        >
                                                            Todos
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div
                                                class="my-5 flex-grow border-t border-gray-400"
                                            ></div>
                                        </div>
                                        <div class="my-3">
                                            <label
                                                for="large-input"
                                                class="mb-2 block text-xs font-medium text-gray-900 dark:text-gray-300"
                                                >Motivo da alteração</label
                                            >
                                            <textarea
                                                type="text"
                                                required
                                                minlength="25"
                                                @blur="validateObservationLimit"
                                                v-model="
                                                    settingData.observation
                                                "
                                                class="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
                            >
                                <button
                                    type="submit"
                                    class="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                                >
                                    Atualizar
                                </button>

                                <button
                                    type="button"
                                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                                    @click="closeModal()"
                                    ref="cancelButtonRef"
                                >
                                    Cancelar
                                </button>
                            </div>
                        </form>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
import { mapWritableState } from "pinia";
import { Store } from "../../../stores/store";
import { useToast } from "vue-toastification";
import PermissionMixin from "../../../mixins/PermissionMixin.vue";
import validateInputInterval from "../../../mixins/ValidateInputInterval.vue";
import FormToggle from "../../../components/forms/FormToggle.vue";

export default {
    components: {
        FormToggle,
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
    mixins: [PermissionMixin, validateInputInterval],
    props: {
        settings: {
            type: Object,
            required: true,
        },
        open: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            reason: "",
            settingData: {
                observation: "",
            },
        };
    },
    mounted() {
        this.settingData = {
            ...this.settingData,
            ...this.settings,
            platform_fixed_fee: this.convertCentsToBRL(
                this.settings.platform_fixed_fee,
            ),
            international_fixed_fee: this.convertCentsToBRL(
                this.settings.international_fixed_fee,
            ),
        };

        this.settingData.anticipation_limit ??= 60;
    },
    computed: {
        ...mapWritableState(Store, ["user", "permissions"]),
    },
    watch: {
        "settingData.cashback_type": function (type) {
            if (type === "percentage") {
                if (this.settingData.cashback_origin === "all") {
                    this.settingData.cashback_origin = "platform";
                }

                if (this.settingData.cashback_fee > 100) {
                    this.settingData.cashback_fee = 100;
                }
            } else if (type === "fixed") {
                this.settingData.cashback_origin = "all";
            }
        },
    },
    methods: {
        closeModal() {
            this.$emit("closeModal");
        },
        convertCentsToBRL(value) {
            if (!value) return null;
            return (parseInt(value) / 100).toFixed(2).toString();
        },
        convertBRLToCents(value) {
            if (!value) return null;
            value = value.toString();
            value = value.replace(",", ".");
            value = parseFloat(value);
            return (value * 100).toString();
        },
        blockUser() {
            if (!this.hasPermission("block.cashouts")) {
                delete this.settingData?.is_cashout_locked;
            }

            axios
                .put(this.settings.links.update, {
                    ...this.settingData,
                    platform_fixed_fee: this.convertBRLToCents(
                        this.settingData.platform_fixed_fee,
                    ),
                    international_fixed_fee: this.convertBRLToCents(
                        this.settingData.international_fixed_fee,
                    ),
                })
                .then(({ data }) => {
                    if (data) {
                        this.toast.success(
                            "Configurações atualizadas com sucesso!",
                        );
                    }
                    this.$emit("closeModalAndFetch");
                })
                .catch(({ response }) => {
                    if (
                        response.hasOwnProperty("data") &&
                        response.data.hasOwnProperty("errors")
                    ) {
                        this.toast.error(response?.data.errors[0]);
                    } else if (
                        response.hasOwnProperty("data") &&
                        response.data.hasOwnProperty("message")
                    ) {
                        this.toast.error(response?.data.message);
                    } else {
                        this.toast.error(
                            "Um erro inesperado ocorreu, as alterações não foram salvas.",
                        );
                    }

                    this.$emit("closeModalAndFetch");
                });
        },
        changeCanAnticipate() {
            this.settingData.can_anticipate = !this.settingData.can_anticipate;
        },
        validateObservationLimit() {
            this.settingData.observation = this.settingData.observation.trim();
        },
    },
};
</script>
