import { createRouter, createWebHistory } from "vue-router";
import Visitor from "./components/fingerprint/Visitor.vue";
import Analytics from "./views/analytics/Analytics.vue";
import ActiveUsers from "./views/analytics/compliance/ActiveUsers.vue";
import ComplianceAnalytics from "./views/analytics/compliance/ComplianceAnalytics.vue";
import ComplianceProducts from "./views/analytics/compliance/products/ComplianceProducts.vue";
import ComplianceUsers from "./views/analytics/compliance/users/ComplianceUsers.vue";
import OrderAnalytics from "./views/analytics/orders/OrderAnalytics.vue";
import Antifraud from "./views/antifraud/Antifraud.vue";
import Audit from "./views/audit/Audit.vue";
import Audits from "./views/audit/Audits.vue";
import AuditMetrics from "./views/audit/metrics/AuditMetrics.vue";
import AuthLayout from "./views/auth/AuthLayout.vue";
import Login from "./views/auth/Login.vue";
import ForgotPassword from "./views/auth/ForgotPassword.vue";
import ResetPassword from "./views/auth/ResetPassword.vue";
import ValidateDevice from "./views/auth/ValidateDevice.vue";
import Blacklist from "./views/blacklist/Blacklist.vue";
import Chat from "./views/chat/Chat.vue";
import CreditCardForm from "./views/checkout/CreditCardForm.vue";
import Compliance from "./views/compliance/Compliance.vue";
import ComplianceDetails from "./views/compliance/ComplianceDetails.vue";
import Config from "./views/config/Config.vue";
import Reasons from "./views/config/Reasons.vue";
import LegalTerms from "./views/config/LegalTerms.vue";
import Costs from "./views/costs/Costs.vue";
import CurrencyGateways from "./views/currencyGateways/CurrencyGateways.vue";
import Emails from "./views/emails/Emails.vue";
import AnticipationControl from "./views/finnancial/AnticipationControl.vue";
import BankAccount from "./views/finnancial/BankAccount.vue";
import BankAccounts from "./views/finnancial/BankAccounts.vue";
import CashoutControl from "./views/finnancial/CashoutControl.vue";
import CashoutReviews from "./views/finnancial/CashoutReviews.vue";
import Dashboard from "./views/finnancial/Dashboard.vue";
import FinnancialLayout from "./views/finnancial/FinnancialLayout.vue";
import ProductStudents from "./views/products/students/ProductStudents.vue";
import Payables from "./views/finnancial/Payables.vue";
import AnticipationDetails from "./views/finnancial/anticipation/AnticipationDetails.vue";
import CashoutDetails from "./views/finnancial/cashout/CashoutDetails.vue";
import Chargebacks from "./views/finnancial/chargebacks/Chargebacks.vue";
import Dispute from "./views/finnancial/chargebacks/disputes/Dispute.vue";
import Disputes from "./views/finnancial/chargebacks/disputes/Disputes.vue";
import PreChargebacks from "./views/finnancial/chargebacks/preckb/PreChargebacks.vue";
import ChargebackUser from "./views/finnancial/chargebacks/users/ChargebackUser.vue";
import ChargebackUsers from "./views/finnancial/chargebacks/users/ChargebackUsers.vue";
import FraudDetector from "./views/fraudDetector/FraudDetector.vue";
import SidebarApp from "./views/layouts/SidebarApp.vue";
import AccountManagers from "./views/managers/AccountManagers.vue";
import Mobile from "./views/mobile/Mobile.vue";
import NoticesBanners from "./views/notices/Banners.vue";
import NoticesList from "./views/notices/List.vue";
import Notices from "./views/notices/Notices.vue";
import Order from "./views/orders/Order.vue";
import Orders from "./views/orders/Orders.vue";
import Product from "./views/products/Product.vue";
import Products from "./views/products/Products.vue";
import ProductMemberArea from "./views/products/members/ProductMemberArea.vue";
import MemberAreaContents from "./views/products/members/resources/MemberAreaContents.vue";
import MemberAreaLessons from "./views/products/members/resources/MemberAreaLessons.vue";
import MemberAreaModules from "./views/products/members/resources/MemberAreaModules.vue";
import RankingLayout from "./views/ranking/RankingLayout.vue";
import Achievements from "./views/ranking/achievements/Achievements.vue";
import Levels from "./views/ranking/levels/Levels.vue";
import Ranking from "./views/ranking/ranking/Ranking.vue";
import S3 from "./views/s3/S3.vue";
import Sellers from "./views/sellers/Sellers.vue";
import Services from "./views/services/Services.vue";
import ShippingProviders from "./views/services/ShippingProviders.vue";
import ViewAIPlans from "./views/services/ViewAI/Plans.vue";
import ViewAIUsers from "./views/services/ViewAI/Users.vue";
import Zapi from "./views/services/Zapi.vue";
import EvolutionAPI from "./views/services/EvolutionApi.vue";
import Recommended from "./views/showcase/Recommended.vue";
import ShowcaseSettings from "./views/showcase/Settings.vue";
import ShowcaseLayout from "./views/showcase/ShowcaseLayout.vue";
import Singlestore from "./views/singlestore/Singlestore.vue";
import Referrals from "./views/users/Referrals.vue";
import Relations from "./views/users/Relations.vue";
import User from "./views/users/User.vue";
import Users from "./views/users/Users.vue";
import AuditUserCommissions from "./views/users/audit/AuditUserCommissions.vue";
import AuditUserFinnancial from "./views/users/audit/AuditUserFinnancial.vue";
import AuditUserAffiliates from "./views/users/audit/AuditUserAffiliates.vue";
import AuditUserMetrics from "./views/users/audit/AuditUserMetrics.vue";
import AuditUserProducts from "./views/users/audit/AuditUserProducts.vue";
import UserAudit from "./views/users/audit/UserAudit.vue";
import UserLogins from "./views/users/audit/UserLogins.vue";
import UserTrackings from "./views/users/audit/UserTrackings.vue";
import Commissions from "./views/users/commissions/Commissions.vue";
import UserFinancial from "./views/users/financial/UserFinancial.vue";
import Permissions from "./views/users/permissions/Permissions.vue";
import UserProducts from "./views/users/products/UserProducts.vue";
import UserSettings from "./views/users/settings/UserSettings.vue";
import AnticipationHistories from "./views/users/statement/AnticipationHistories.vue";
import Statement from "./views/users/statement/Statement.vue";
import StatementLog from "./views/users/statement/StatementLog.vue";
import Videos from "./views/videos/Videos.vue";
import Webhooks from "./views/webhooks/Webhooks.vue";
import UserPlates from "./views/users/plates/UserPlates.vue";
import Plates from "./views/ranking/plates/Plates.vue";
import Translations from "./views/translations/Translations.vue";
import ReviewsCockpit from "./views/finnancial/ReviewsCockpit.vue";
import OrderSearch from "./views/orders/OrderSearch.vue";
import AuditInteractions from "./components/audit/AuditInteractions.vue";
import RiskDashboard from "./views/risk/Dashboard.vue";
import Profile from "./views/users/Profile.vue";
import AuditBatches from "./views/audit/AuditBatches.vue";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            component: SidebarApp,
            children: [
                {
                    path: "/s3",
                    component: S3,
                    name: "s3",
                    meta: { title: "S3" },
                },
                {
                    path: "/fraud-detector",
                    component: FraudDetector,
                    name: "fraud-detector",
                    meta: { title: "Fraud Detector" },
                },
                {
                    path: "/user",
                    component: Users,
                    name: "users",
                    meta: { title: "Usuários" },
                },
                {
                    path: "/profile",
                    component: Profile,
                    name: "profile",
                    meta: { title: "Perfil" },
                },
                {
                    path: "/chat",
                    component: Chat,
                    name: "chat",
                    meta: { title: "Chat" },
                },
                {
                    path: "/audit",
                    component: Audits,
                    name: "audit",
                    meta: { title: "Auditoria" },
                },
                {
                    path: "/sellers",
                    component: Sellers,
                    name: "sellers",
                    meta: { title: "Sellers" },
                },
                {
                    path: "/webhooks",
                    component: Webhooks,
                    name: "webhooks",
                    meta: { title: "Webhooks" },
                },
                {
                    path: "/audit/metrics",
                    component: AuditMetrics,
                    name: "audit.metrics",
                    meta: { title: "Auditoria" },
                },
                {
                    path: "/audit/:audit_id",
                    component: Audit,
                    name: "audit.single",
                    meta: { title: "Auditoria" },
                    children: [
                        {
                            path: "interactions",
                            name: "audit.interactions",
                            component: AuditInteractions,
                        },
                        {
                            path: "metrics",
                            name: "audit.metrics",
                            component: AuditUserMetrics,
                        },
                        {
                            path: "trackings",
                            name: "audit.trackings",
                            component: UserTrackings,
                        },
                        {
                            path: "products",
                            name: "audit.products",
                            component: AuditUserProducts,
                        },
                        {
                            path: "commissions",
                            name: "audit.commissions",
                            component: AuditUserCommissions,
                        },
                        {
                            path: "logins",
                            name: "audit.logins",
                            component: UserLogins,
                        },
                        {
                            path: "finnancial",
                            name: "audit.finnancial",
                            component: AuditUserFinnancial,
                            meta: { title: "Financeiro" },
                        },
                    ],
                },
                {
                    path: "/visitor",
                    component: Visitor,
                    name: "visitor",
                    meta: { title: "Visitante" },
                },
                {
                    path: "/antifraud",
                    component: Antifraud,
                    name: "antifraud",
                    meta: { title: "Antifraude" },
                },
                {
                    path: "/translations",
                    component: Translations,
                    name: "translations",
                    meta: { title: "Traduções" },
                },
                {
                    path: "/singlestore",
                    component: Singlestore,
                    name: "singlestore",
                    meta: { title: "Singlestore" },
                },
                {
                    path: "/visitor/:visitor_id",
                    component: Visitor,
                    name: "visitor.events",
                    meta: { title: "Visitante" },
                },
                {
                    path: "/compliance",
                    name: "compliance",
                    meta: { title: "Compliance" },
                    component: Compliance,
                },
                {
                    path: "/emails",
                    name: "emails",
                    meta: { title: "Emails" },
                    component: Emails,
                },
                {
                    path: "/account-managers",
                    name: "account-managers",
                    meta: { title: "Gerentes" },
                    component: AccountManagers,
                },
                {
                    path: "/notices",
                    name: "notices",
                    meta: { title: "Avisos" },
                    component: Notices,
                    children: [
                        {
                            path: "list",
                            name: "notices.list",
                            component: NoticesList,
                        },
                        {
                            path: "banners",
                            name: "notices.banners",
                            component: NoticesBanners,
                        },
                    ],
                },
                {
                    path: "/credit-card",
                    name: "credit-card",
                    meta: { title: "Cartões" },
                    component: CreditCardForm,
                },
                {
                    path: "/mobile",
                    name: "mobile",
                    meta: { title: "Mobile" },
                    component: Mobile,
                },
                {
                    path: "/videos",
                    name: "videos",
                    meta: { title: "Videos" },
                    component: Videos,
                },
                {
                    path: "/compliance/:user_id",
                    name: "compliance.details",
                    meta: { title: "Compliance" },
                    component: ComplianceDetails,
                },
                {
                    path: "/user/:user_id",
                    name: "user.details" /* webpackChunkName: "users" */,
                    meta: { title: "Usuário" },
                    component: User,
                    children: [
                        {
                            path: "audit",
                            name: "user.audit",
                            component: UserAudit,
                            children: [
                                {
                                    path: "metrics",
                                    name: "user.metrics",
                                    component: AuditUserMetrics,
                                },
                                {
                                    path: "trackings",
                                    name: "user.trackings",
                                    component: UserTrackings,
                                },
                                {
                                    path: "products",
                                    name: "user.audit.products",
                                    component: AuditUserProducts,
                                },
                                {
                                    path: "commissions",
                                    name: "user.audit.commissions",
                                    component: AuditUserCommissions,
                                },
                                {
                                    path: "logins",
                                    name: "user.audit.logins",
                                    component: UserLogins,
                                },
                                {
                                    path: "finnancial",
                                    name: "user.audit.finnancial",
                                    component: AuditUserFinnancial,
                                    meta: { title: "Financeiro" },
                                },
                                {
                                    path: "affiliates",
                                    name: "user.audit.affiliates",
                                    component: AuditUserAffiliates,
                                },
                            ],
                        },
                        {
                            path: "commissions",
                            name: "user.commissions",
                            component: Commissions,
                        },
                        {
                            path: "relations",
                            name: "user.relations",
                            component: Relations,
                        },
                        {
                            path: "referrals",
                            name: "user.referrals",
                            component: Referrals,
                        },
                        {
                            path: "statement",
                            name: "user.statement",
                            component: Statement,
                        },
                        {
                            path: "log",
                            name: "user.statement-log",
                            component: StatementLog,
                        },
                        {
                            path: "anticipation/histories",
                            name: "user.anticipation-histories",
                            component: AnticipationHistories,
                        },
                        {
                            path: "permissions",
                            name: "user.permissions",
                            component: Permissions,
                        },
                        {
                            path: "details",
                            name: "user.settings",
                            component: UserSettings,
                        },
                        {
                            path: "financial",
                            name: "user.financial",
                            component: UserFinancial,
                        },
                        {
                            path: "products",
                            name: "user.products",
                            component: UserProducts,
                        },
                        {
                            path: "plates",
                            name: "user.plates",
                            component: UserPlates,
                        },
                    ],
                },
                {
                    path: "/products",
                    name: "products",
                    component: Products,
                    meta: { title: "Produtos" },
                },
                {
                    path: "/product/:product_id",
                    name: "product",
                    component: Product,
                    meta: { title: "Produto" },
                },
                {
                    path: "/product/:product_id/members",
                    name: "product.members",
                    component: ProductMemberArea,
                    meta: { title: "Área de membros" },
                    children: [
                        {
                            path: "contents",
                            name: "product.members.contents",
                            component: MemberAreaContents,
                        },
                        {
                            path: "modules",
                            name: "product.members.modules",
                            component: MemberAreaModules,
                        },
                        {
                            path: "lessons",
                            name: "product.members.lessons",
                            component: MemberAreaLessons,
                        },
                    ],
                },
                {
                    path: "/product/:product_id/students",
                    name: "product.students",
                    component: ProductStudents,
                    meta: { title: "Alunos" },
                },
                {
                    path: "/orders",
                    name: "orders",
                    component: Orders,
                    meta: { title: "Pedidos" },
                },
                {
                    path: "/orders/search",
                    name: "orders.search",
                    component: OrderSearch,
                    meta: { title: "Pedidos" },
                },
                {
                    path: "/order/:order_id",
                    name: "user.order",
                    component: Order,
                    meta: { title: "Pedido" },
                },
                {
                    path: "/analytics",
                    name: "analytics",
                    component: Analytics,
                    meta: { title: "Analytics" },
                    children: [
                        {
                            path: "compliance",
                            name: "analytics.compliance",
                            component: ComplianceAnalytics,
                            children: [
                                {
                                    path: "users",
                                    name: "analytics.compliance.users",
                                    component: ComplianceUsers,
                                },
                                {
                                    path: "products",
                                    name: "analytics.compliance.products",
                                    component: ComplianceProducts,
                                },
                            ],
                        },
                        {
                            path: "orders",
                            name: "analytics.orders",
                            component: OrderAnalytics,
                        },
                        {
                            path: "active-users",
                            name: "analytics.active-users",
                            component: ActiveUsers,
                        },
                    ],
                },
                {
                    component: FinnancialLayout,
                    path: "/financial",
                    meta: { title: "Financeiro" },
                    children: [
                        {
                            path: "dashboard",
                            name: "finnancial.dashboard",
                            component: Dashboard,
                        },
                        {
                            path: "chargebacks",
                            name: "finnancial.chargebacks",
                            component: Chargebacks,
                            children: [
                                {
                                    path: "disputes",
                                    name: "finnancial.chargebacks.disputes",
                                    component: Disputes,
                                },
                                {
                                    path: "disputes/:dispute_id",
                                    name: "finnancial.chargebacks.dispute",
                                    component: Dispute,
                                },
                                {
                                    path: "users",
                                    name: "finnancial.chargebacks.users",
                                    component: ChargebackUsers,
                                },
                                {
                                    path: "users/:user_id",
                                    name: "finnancial.chargebacks.user",
                                    component: ChargebackUser,
                                },
                                {
                                    path: "pre-chargebacks",
                                    name: "finnancial.chargebacks.pre-chargebacks",
                                    component: PreChargebacks,
                                },
                            ],
                        },
                        {
                            path: "payables",
                            name: "finnancial.payables",
                            component: Payables,
                        },
                        {
                            path: "bank-accounts",
                            name: "finnancial.bank-accounts",
                            component: BankAccounts,
                        },
                        {
                            path: "cashout-control",
                            redirect: {
                                name: "finnancial.cashout-control",
                            },
                        },
                        {
                            path: "cashouts",
                            name: "finnancial.cashout-control",
                            component: CashoutControl,
                        },
                        {
                            path: "anticipation-control",
                            name: "finnancial.anticipation-control",
                            component: AnticipationControl,
                        },
                        {
                            path: "reviews",
                            name: "finnancial.reviews",
                            component: CashoutReviews,
                            meta: { title: "Reviews - Financeiro" },
                        },
                        {
                            path: "reviews/cockpit",
                            name: "finnancial.reviews.cockpit",
                            component: ReviewsCockpit,
                            meta: { title: "Reviews - Financeiro" },
                        },
                        {
                            path: "costs",
                            name: "costs",
                            component: Costs,
                            meta: { title: "Custos Mensais" },
                        },
                        {
                            path: "currency-gateway",
                            name: "finnancial.currency-gateway",
                            component: CurrencyGateways,
                            meta: { title: "Moedas" },
                        },
                    ],
                },
                {
                    path: "/financial/bank-account/:account_id",
                    name: "finnancial.bank-account",
                    component: BankAccount,
                    meta: { title: "Detalhes da Conta" },
                },
                {
                    path: "/financial/cashout-details/:cashout_id",
                    name: "finnancial.cashout-details",
                    component: CashoutDetails,
                    meta: { title: "Detalhes do Saque" },
                },
                {
                    path: "/financial/anticipation-details/:anticipation_id",
                    name: "finnancial.anticipation-details",
                    component: AnticipationDetails,
                    meta: { title: "Detalhes da Antecipação" },
                },

                {
                    component: ShowcaseLayout,
                    path: "/showcase",
                    meta: { title: "Showcase" },
                    children: [
                        {
                            path: "settings",
                            name: "showcase.settings",
                            component: ShowcaseSettings,
                        },
                        {
                            path: "recommended",
                            name: "showcase.recommended",
                            component: Recommended,
                        },
                    ],
                },
                {
                    path: "/services",
                    name: "services",
                    component: Services,
                    meta: { title: "Serviços" },
                },
                {
                    path: "/services/zapi",
                    name: "services.zapi",
                    component: Zapi,
                },
                {
                    path: "/services/evolution-api",
                    name: "services.evolution-api",
                    component: EvolutionAPI,
                },
                {
                    path: "/services/viewai/plans",
                    name: "services.viewai.plans",
                    component: ViewAIPlans,
                },
                {
                    path: "/services/viewai/users",
                    name: "services.viewai.users",
                    component: ViewAIUsers,
                },
                {
                    path: "/services/shipping-providers",
                    name: "services.shipping-providers",
                    component: ShippingProviders,
                },

                {
                    path: "/blacklist",
                    name: "blacklist.index",
                    component: Blacklist,
                    meta: { title: "blacklist" },
                },
                {
                    path: "/config",
                    name: "config",
                    component: Config,
                    meta: { title: "Configurações" },
                    children: [
                        {
                            path: "reasons",
                            name: "reasons",
                            component: Reasons,
                        },
                        {
                            path: "legal-terms",
                            name: "legal-terms",
                            component: LegalTerms,
                        },
                    ],
                },
                {
                    component: RankingLayout,
                    path: "/ranking",
                    meta: { title: "Ranking" },
                    children: [
                        {
                            path: "levels",
                            name: "ranking.levels",
                            component: Levels,
                        },
                        {
                            path: "achievements",
                            name: "ranking.achievements",
                            component: Achievements,
                        },
                        {
                            path: "",
                            name: "ranking.ranking",
                            component: Ranking,
                        },
                        {
                            path: "plates",
                            name: "ranking.plates",
                            component: Plates,
                        },
                    ],
                },
                {
                    path: "/risk/dashboard",
                    name: "risk.dashboard",
                    component: RiskDashboard,
                    meta: {
                        requiresAuth: true,
                        title: "Dashboard de Risco",
                    },
                },
                {
                    path: "/audit-batches",
                    name: "audit.batches",
                    component: AuditBatches,
                    meta: {
                        title: "Batches de Auditoria",
                        requiresAuth: true,
                    },
                },
            ],
        },

        {
            path: "/auth",
            component: AuthLayout,
            children: [
                {
                    path: "login",
                    name: "login",
                    component: Login,
                    meta: { title: "Login" },
                },
                {
                    path: "forgot-password",
                    name: "password.request",
                    component: ForgotPassword,
                    meta: { title: "Esqueci minha senha" },
                },
                {
                    path: "reset-password/:token",
                    name: "password.reset",
                    component: ResetPassword,
                    meta: { title: "Redefinir senha" },
                },
                {
                    path: "validate-device/:uuid/",
                    name: "validateDevice",
                    component: ValidateDevice,
                    meta: { title: "Login" },
                },
            ],
        },
    ],
});

export default router;
