<template>
    <div class="flex flex-col dark:text-white">
        <filters
            date-filter
            v-model="filters"
            :loading="loading"
            @fetch="
                cashouts = [];
                next_page_url = false;
                fetch();
            "
            search-filter
            search-label="Filtre pelo nome, e-mail ou documento"
            status-filter
            :statuses="[
                { value: 'requested', label: 'Solicitado' },
                { value: 'sent_to_bank', label: 'Enviado ao Banco' },
                { value: 'approved', label: 'Aprovado' },
                { value: 'returned', label: 'Retornado' },
                { value: 'in_audit', label: 'Em Auditoria' },
            ]"
            :defaultStatus="['requested']"
            type-filter
            type-label="Conta"
        >
            <select class="px-2 border dark:border-white dark:text-white text-black dark:bg-slate-800 rounded-md" v-model="receiver_type">
                <option :value="null">Recebedor</option>
                <option value="pix">Pix</option>
                <option value="bank">Conta Bancária</option>
            </select>
            <bank-select v-model="selectedBanks" />
        </filters>

        <TransitionRoot
            appear
            :show="selectedCashouts.length > 0"
            enter="transition-opacity duration-75"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity duration-150"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
            <div
                class="w-ful absolute z-50 mb-4 grid max-w-4xl grid-cols-4 divide-x divide-slate-700 rounded-lg bg-slate-800 shadow"
            >
                <div
                    class="rounded-lg p-3 hover:cursor-pointer hover:bg-slate-900"
                >
                    <input
                        @click.stop="selectAll"
                        :checked="all_selected"
                        id="select-all-cashouts"
                        aria-describedby="comments-description"
                        name="all-cashouts"
                        type="checkbox"
                        class="h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                        :value="true"
                    />
                    {{ selectedCashouts.length }} Selecionado{{
                        selectedCashouts.length > 1 ? "s" : ""
                    }}
                </div>
                <div
                    @click.prevent="exportSispag"
                    class="flex content-center justify-center rounded-lg p-3 hover:cursor-pointer hover:bg-slate-900"
                >
                    <SvgLoader v-if="loadingExport" />
                    <CloudArrowDownIcon
                        class="mr-1 h-5 w-5 flex-shrink-0 self-center"
                    />
                    Exportar
                </div>
                <div
                    @click.prevent="archive"
                    class="flex content-center justify-center rounded-lg p-3 hover:cursor-pointer hover:bg-slate-900"
                >
                    <SvgLoader v-if="loadingExport" />
                    <CloudArrowDownIcon
                        class="mr-1 h-5 w-5 flex-shrink-0 self-center"
                    />
                    Arquivar
                </div>
                <div
                    @click.prevent="exportExcel"
                    class="flex content-center justify-center rounded-lg p-3 hover:cursor-pointer hover:bg-slate-900"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-file-excel"
                        class="mr-1 h-5 w-5"
                    />
                    Excel
                </div>
            </div>
        </TransitionRoot>

        <dl class="my-3 grid grid-cols-1 gap-5 sm:grid-cols-3">
            <card :loading="loading">
                <p
                    class="mb-2 text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                >
                    Saques
                </p>
                <p
                    class="flex text-2xl font-light text-gray-700 dark:text-gray-200"
                >
                    {{ cards.total }}
                </p>
            </card>
            <card :loading="loading">
                <p
                    class="mb-2 text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                >
                    Total Solicitado
                </p>
                <p
                    class="flex text-2xl font-light text-gray-700 dark:text-gray-200"
                >
                    {{ $root.formatCurrency(cards.total_amount) }}
                </p>
            </card>
            <card :loading="loading">
                <p
                    class="mb-2 text-sm font-bold uppercase text-gray-400 dark:text-gray-400"
                >
                    Total Líquido
                </p>
                <p
                    class="flex text-2xl font-light text-gray-700 dark:text-gray-200"
                >
                    {{ $root.formatCurrency(cards.net_value ?? 0) }}
                </p>
            </card>
        </dl>

        <div class="mb-3 flex">
            <div class="ml-auto flex items-center">
                <button
                    class="btn-primary-sm ml-auto mr-2"
                    :disabled="loading"
                    @click.prevent="exportCashouts"
                >
                    <ArrowDownTrayIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-white"
                        aria-hidden="true"
                    />
                    Exportar
                </button>

                Exibindo {{ pagination.from }} a {{ pagination.to }} registros
                de {{ pagination.total }}
            </div>
        </div>
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full sm:px-6 lg:px-8">
                <div
                    class="not-prose relative overflow-hidden overflow-hidden rounded-xl bg-slate-50 dark:bg-slate-800/25"
                >
                    <table class="w-full table-auto border-collapse text-sm">
                        <thead>
                            <tr>
                                <th
                                    scope="col"
                                    class="px-6 py-4 text-left text-sm font-medium"
                                >
                                    <input
                                        @click.stop="selectAll"
                                        :checked="all_selected"
                                        id="select-all-cashouts"
                                        aria-describedby="comments-description"
                                        name="all-cashouts"
                                        type="checkbox"
                                        class="mr-3 h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                                        :value="true"
                                    />
                                </th>
                                <th
                                    v-for="column in [
                                        'Data',
                                        'Nome do Favorecido',
                                        'Banco',
                                        'Valor',
                                        'Status',
                                    ]"
                                    scope="col"
                                    class="px-2 py-4 text-left text-sm font-medium"
                                >
                                    {{ column }}
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white dark:bg-slate-800">
                            <tr v-for="cashout in cashouts">
                                <td
                                    class="border-b border-slate-100 p-3 pl-6 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    <div class="flex">
                                        <input
                                            :id="'check-cashout-' + cashout.id"
                                            aria-describedby="comments-description"
                                            name="cashouts"
                                            type="checkbox"
                                            class="mr-3 h-4 w-4 rounded border-red-300 text-red-300 checked:shadow-xl focus:ring-indigo-500"
                                            v-model="selectedCashouts"
                                            :value="cashout.id"
                                        />
                                        <router-link
                                            :to="{
                                                name: 'finnancial.cashout-details',
                                                params: {
                                                    cashout_id: cashout.id,
                                                },
                                            }"
                                            class="text-indigo-400 hover:cursor-pointer hover:text-indigo-700 hover:underline"
                                        >
                                            #{{ cashout.id }}
                                        </router-link>
                                    </div>
                                </td>
                                <td
                                    class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ cashout.created_at }}
                                </td>

                                <td
                                    class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{ cashout.bank_account.holder_name }}
                                </td>
                                <td
                                    class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                    v-if="cashout?.bank_account?.receiver_type === 'bank'"
                                >
                                    {{ cashout.bank_account?.bank?.code }} -
                                    {{ cashout.bank_account?.bank?.name }}
                                </td>
                                <td
                                    class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                    v-else
                                >
                                   PIX: {{ cashout.bank_account?.pix_key }}
                                </td>
                                <td
                                    class="border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    {{
                                        $root.formatCurrency(cashout.net_value)
                                    }}
                                </td>
                                <td
                                    class="items-center justify-center border-b border-slate-100 p-3 text-slate-500 dark:border-slate-700 dark:text-slate-200"
                                >
                                    <div
                                        class="w-full rounded px-2.5 py-1.5 text-center text-sm font-medium"
                                        :class="
                                            getCashoutStatusClass(
                                                cashout.status,
                                            )
                                        "
                                    >
                                        {{
                                            getCashoutStatusName(cashout.status)
                                        }}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="my-5 flex w-full">
                        <div class="mx-auto flex flex-col items-center">
                            <span class="mb-1">
                                Exibindo {{ pagination.from }} a
                                {{ pagination.to }} registros de
                                {{ pagination.total }}
                            </span>
                            <pagination
                                :pagination="pagination"
                                :offset="1"
                                @paginate="fetch"
                            ></pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueSelect from "../../components/forms/VueSelect.vue";
import Pagination from "../../components/layouts/Pagination.vue";
import Filters from "../../components/layouts/Filters.vue";
import SvgLoader from "../../components/layouts/SvgLoader.vue";
import { mapWritableState, mapActions } from "pinia";
import { Store } from "../../stores/store.js";
import HelperMixin from "../../mixins/HelperMixin.vue";
import BankMixin from "../../mixins/BankMixin.vue";
import {
    ArrowDownTrayIcon,
    CloudArrowDownIcon,
} from "@heroicons/vue/24/outline";
import { TransitionRoot } from "@headlessui/vue";
import Card from "../../components/layouts/Card.vue";
import MultipleSelect from "../../components/forms/MultipleSelect.vue";
import BankSelect from "../../components/forms/BankSelect.vue";
import UsersSelect from "../../components/forms/UsersSelect.vue";
import { useToast } from "vue-toastification";

export default {
    components: {
        UsersSelect,
        BankSelect,
        MultipleSelect,
        ArrowDownTrayIcon,
        Card,
        VueSelect,
        Pagination,
        CloudArrowDownIcon,
        TransitionRoot,
        SvgLoader,
        Filters,
    },
    mixins: [HelperMixin, BankMixin],

    setup() {
        const toast = useToast();
        return { toast };
    },

    data() {
        return {
            loadingExport: false,
            loading: true,
            next_page_url: false,
            cashouts: [],
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                date: [],
                status: ["requested"],
                receiver_type: null,
                types: [],
            },
            receiver_type: null,
            banks: [],
            selectedBanks: [],
            selectedCashouts: [],
            all_selected: false,
            cards: {
                total: 0,
                total_amount: 0,
            },
        };
    },

    watch: {
        selectedCashouts(array) {
            if (array.length === 0) {
                this.all_selected = false;
            }
        },
    },

    computed: {
        ...mapWritableState(Store, ["storeCashout"]),
        filteredStatuses() {
            return this.filters.status.map((status) => {
                return {
                    status: status,
                    name: this.getCashoutStatusName(status),
                };
            });
        },
        filteredTypes() {
            return this.filters.types
                .map((type) => {
                    return this.getCashoutAccountType(type);
                })
                .join(", ");
        },
    },

    mounted() {
        this.storeCashout = false;
        this.storeProduct = false;
        this.filters.status = ["requested"];

        setTimeout(() => {
            this.fetch();
        }, 1000);
    },

    methods: {
        exportCashouts() {
            this.loading = true;
            const params = this.filters;

            axios
                .get("/api/cashout/export/csv", {
                    params: {
                        cashout_ids: this.selectedCashouts,
                        ...params,
                    },
                })
                .then(({ data }) => {
                    this.loading = false;
                    this.toast.success(
                        "Exportação iniciada com sucesso, você receberá o relatório por e-mail em alguns minutos.",
                    );
                });
        },
        removeStatus(status) {
            this.filters.status = this.filters.status.filter((item) => {
                return item !== status.status;
            });
        },
        archive() {
            if (this.loadingExport) {
                return false;
            }

            if (this.selectedCashouts.length < 1) {
                return this.$toast.error(error.response.data.message);
            }

            this.loadingExport = true;

            axios
                .post("/api/cashout/archive", {
                    cashout_ids: this.selectedCashouts,
                })
                .then(({ data }) => {
                    this.loadingExport = false;
                    this.selectedCashouts = [];

                    this.next_page_url = false;
                    this.fetch();
                })
                .catch((error) => {
                    this.loadingExport = false;
                    if (error.response) {
                        return this.$toast.error(error.response.data.message);
                    }
                });
        },
        exportSispag() {
            if (this.loadingExport) {
                return false;
            }

            if (this.selectedCashouts.length < 1) {
                return this.$toast.error(error.response.data.message);
            }

            this.loadingExport = true;

            const params = this.filters;

            axios
                .get("/api/cashout/export/sispag", {
                    params: {
                        cashout_ids: this.selectedCashouts,
                        ...params,
                    },
                })
                .then(({ data }) => {
                    window.open(data.url, "_blank");
                    this.loadingExport = false;
                })
                .catch((error) => {
                    this.loadingExport = false;
                    if (error.response) {
                        return this.$toast.error(error.response.data.message);
                    }
                });
        },
        exportExcel() {
            if (this.loadingExport) {
                return false;
            }
            if (this.receiver_type) {
                this.filters["filter[receiver_type]"] = this.receiver_type;
            }

            this.loadingExport = true;

            axios
                .get("/api/cashout/export/excel", {
                    params: this.filters,
                })
                .then(({ data }) => {
                    this.loadingExport = false;
                    window.open(data.url, "_blank");
                })
                .catch((error) => {
                    this.loadingExport = false;
                    if (error.response) {
                        return this.$toast.error(error.response.data.message);
                    }
                });
        },
        selectAll() {
            this.all_selected = !this.all_selected;
            if (this.all_selected) {
                axios
                    .get("/api/cashout/select-all", {
                        params: this.filters,
                    })
                    .then(({ data }) => {
                        this.selectedCashouts = data;
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$toast.error(error.response.data.message);
                    });
            } else {
                this.selectedCashouts = [];
            }
        },
        clearFilters() {
            this.cashouts = [];
            this.filters.status = [];
            this.filters.types = [];
            this.filters.text = "";
            this.next_page_url = false;
            this.fetch();
        },
        manage(cashout) {
            this.storeCashout = cashout;
            this.$router.push({
                name: "finnancial.cashout-details",
                params: { cashout_id: cashout.id },
            });
        },
        filterParams() {
            const params = {};
            params["page"] = this.pagination.current_page;
            params["filter[status]"] = this.filters.status.join();
            params["filter[text]"] = this.filters.text;
            params["filter[receiver_type]"] = this.receiver_type;
            if (this.selectedBanks.length > 0) {
                params["filter[bank_id]"] = this.selectedBanks.join();
            }
            return params;
        },
        fetch() {
            this.loading = true;

            const params = this.filters;
            params["page"] = this.pagination.current_page;
            if (this.selectedBanks.length > 0) {
                params["filter[bank_id]"] = this.selectedBanks.join();
            }

            if (this.receiver_type) {
                params["filter[receiver_type]"] = this.receiver_type;
            }

            axios
                .get("/api/cashout", {
                    params: params,
                })
                .then(({ data }) => {
                    this.cashouts = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data,
                    };

                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.$toast.error(error.response.data.message);
                });

            axios
                .get("/api/cashout/cards", {
                    params: params,
                })
                .then(({ data }) => {
                    this.cards = data;
                });
        },
    },
};
</script>
