<template>

</template>  

<script>
export default {
    props: {
        options: {
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        register() {
            this.loading = true;
            axios.post('/api/passkeys/register', this.options)
                .then(({data}) => {
                    console.log('register', data);
                });
        },
    }
}    
</script>