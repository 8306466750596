<template>
    <div class="flex flex-col dark:text-white">
        <filters
            v-model="filters"
            @fetch="
                pagination.current_page = 1;
                users = [];
                next_page_url = false;
                fetch();
            "
            :loading="loading"
            search-filter
            search-label="Filtre por nome, e-mail, cpf ou cnpj"
            class="my-5"
        />

        <ticto-loading v-if="loading" />
        <div v-else class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full sm:px-6 lg:px-8">
                <div
                    class="not-prose relative overflow-hidden rounded-xl bg-slate-50 dark:bg-slate-800/25"
                >
                    <ul role="list" class="divide-y dark:divide-slate-700">
                        <li v-for="user in users" :key="user.email">
                            <a
                                @click.prevent="manage(user)"
                                class="block hover:cursor-pointer hover:bg-gray-300 dark:bg-slate-800 dark:hover:bg-slate-900"
                            >
                                <div class="flex items-center px-4 py-5">
                                    <div
                                        class="flex min-w-0 flex-1 items-center"
                                    >
                                        <div class="flex-shrink-0">
                                            <dice-bear-avatar
                                                :seed="user.name"
                                                :url="user.avatar"
                                            />
                                        </div>
                                        <div
                                            class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4"
                                        >
                                            <div>
                                                <p
                                                    class="truncate text-sm font-medium text-gray-900 dark:text-indigo-600"
                                                >
                                                    {{ user.name }}
                                                </p>
                                                <p
                                                    class="mt-2 flex items-center text-sm text-gray-500"
                                                >
                                                    <EnvelopeIcon
                                                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                        aria-hidden="true"
                                                    />
                                                    <span class="truncate">{{
                                                        user.email
                                                    }}</span>
                                                </p>
                                                <p
                                                    class="mt-2 flex items-center text-sm"
                                                >
                                                    <span
                                                        v-if="user.deleted_at"
                                                        class="flex flex-wrap"
                                                    >
                                                        <UserCircleIcon
                                                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
                                                            aria-hidden="true"
                                                        />
                                                        Usuário Inativo
                                                    </span>
                                                    <span
                                                        v-if="!user.deleted_at"
                                                        class="flex flex-wrap"
                                                    >
                                                        <UserCircleIcon
                                                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                                            aria-hidden="true"
                                                        />
                                                        Usuário Ativo
                                                    </span>
                                                </p>
                                            </div>
                                            <div
                                                class="ml-auto hidden md:block"
                                            >
                                                <div class="dark:text-white">
                                                    <p
                                                        class="flex flex-wrap text-sm dark:text-white"
                                                    >
                                                        <ClockIcon
                                                            class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                                            aria-hidden="true"
                                                        />
                                                        <time
                                                            :datetime="
                                                                user.created_at
                                                            "
                                                            >{{
                                                                user.created_at
                                                            }}</time
                                                        >
                                                    </p>
                                                    <p
                                                        class="mt-2 flex items-center text-sm"
                                                    >
                                                        <span
                                                            v-if="
                                                                user.is_producer
                                                            "
                                                            class="flex flex-wrap"
                                                        >
                                                            <CheckCircleIcon
                                                                class="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                                                aria-hidden="true"
                                                            />
                                                            Produtor
                                                        </span>
                                                    </p>
                                                    <p
                                                        class="mt-2 flex items-center text-sm"
                                                    >
                                                        <span
                                                            v-if="
                                                                user?.setting
                                                                    ?.is_blocked
                                                            "
                                                            class="flex flex-wrap"
                                                        >
                                                            <NoSymbolIcon
                                                                class="mr-1.5 h-5 w-5 flex-shrink-0 text-red-400"
                                                                aria-hidden="true"
                                                            />
                                                            Bloqueado
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="!user.deleted_at">
                                        <ChevronRightIcon
                                            class="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div
                                        v-if="
                                            user.deleted_at &&
                                            hasPermission('delete.users')
                                        "
                                    >
                                        <button
                                            @click.stop="
                                                reactivateUser(user.id)
                                            "
                                            type="button"
                                            class="inline-flex items-center rounded-lg bg-green-600 px-2 py-1 text-center text-xs font-bold font-medium text-white hover:bg-green-800 focus:ring-4 focus:ring-green-200 dark:focus:ring-green-900"
                                        >
                                            Ativar Usuário
                                        </button>
                                    </div>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
CheckCircleIcon,
ChevronRightIcon,
ClockIcon,
EnvelopeIcon,
NoSymbolIcon,
UserCircleIcon,
} from "@heroicons/vue/24/outline";
import { mapWritableState } from "pinia";
import FormToggle from "../../components/forms/FormToggle.vue";
import Filters from "../../components/layouts/Filters.vue";
import Pagination from "../../components/layouts/Pagination.vue";
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import { Store } from "../../stores/store";
import DiceBearAvatar from "../../components/common/DiceBearAvatar.vue";

export default {
    components: {
        DiceBearAvatar,
        FormToggle,
        TictoLoading,
        Filters,
        Pagination,
        ChevronRightIcon,
        CheckCircleIcon,
        EnvelopeIcon,
        NoSymbolIcon,
        ClockIcon,
        UserCircleIcon,
    },
    data() {
        return {
            opensearch: 1,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
            },
            loading: false,
            users: [],
            next_page_url: false,
        };
    },
    computed: {
        ...mapWritableState(Store, ["user", "permissions"]),
    },

    mounted() {},

    methods: {
        fetch() {
            this.loading = true;

            const params = this.filters;
            params["page"] = this.pagination.current_page;
            params["opensearch"] = this.opensearch;

            axios
                .get("/api/user", {
                    params: params,
                })
                .then(({ data }) => {
                    this.users = data;

                    this.pagination.current_page += 1;
                    this.next_page_url = data.next_page_url;
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        hasPermission(name) {
            return this.permissions.find(
                (permission) => permission.name === name,
            );
        },
        async reactivateUser(id) {
            this.loading = true;

            await axios
                .put(`/api/user/${id}/reactivate`, { user_id: id })
                .then(({ data }) => {
                    this.users = [];
                    this.pagination.current_page = 0;
                    this.fetch();

                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                });
        },
        manage(user) {
            this.user = user;
            this.$router.push({
                name: "user.commissions",
                params: {
                    user_id: user.id,
                },
            });
        },
        formatCurrency(value) {
            return (value / 100).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
        },
    },
};
</script>
