<template>
    <img
        :class="avatarClass"
        :src="avatarUrl"
    />
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            required: false,
            default: null
        },
        seed: {
            type: String,
            required: true
        },
        class: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        avatarUrl() {
            const limitedSeed = this.seed.split(' ')[0];
            return this.url || `https://api.dicebear.com/9.x/initials/svg?backgroundColor=c0aede&seed=${limitedSeed}`
        },
        avatarClass() {
            return this.class || 'h-12 w-12 rounded-full'
        }
    }
}
</script> 