<template>
    <label class="inline-flex cursor-pointer items-center">
        <input
            type="checkbox"
            :checked="banner.is_active"
            @change="toggle"
            :disabled="loading"
            class="sr-only"
        />
        <div
            :class="{
                'bg-indigo-500': banner.is_active,
                'bg-gray-500': !banner.is_active,
                'cursor-not-allowed': loading,
            }"
            class="relative flex h-6 w-10 items-center rounded-full transition duration-200 ease-in-out"
        >
            <span
                :class="{
                    'translate-x-4': banner.is_active,
                    'translate-x-0': !banner.is_active,
                }"
                class="mx-0.5 my-auto inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out"
            ></span>
        </div>
        <span class="ml-3 text-sm font-medium text-white">
            {{ banner.is_active ? "Desativar" : "Ativar" }}
        </span>
    </label>
</template>

<script>
export default {
    props: ["banner"],

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        toggle() {
            this.loading = true;

            axios
                .put("/api/banners/" + this.banner.id + "/toggle")
                .then(({ data }) => {
                    this.$emit("fetch");
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
/* Estilos adicionais para o toggle switch */
input:checked + div {
    background-color: #6366f1; /* bg-indigo-500 */
}

input:not(:checked) + div {
    background-color: #718096; /* bg-gray-500 */
}

input:checked + div span {
    transform: translateX(1.25rem); /* translate-x-5 */
}

input:not(:checked) + div span {
    transform: translateX(0);
}
</style>
