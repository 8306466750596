<template>
    <div>
        <card
            class="mb-3 flex flex-col dark:text-white"
            v-if="audit && showCard"
        >
            <p class="text-xs font-thin dark:text-slate-500">Detalhes</p>
            <div class="mb-2 flex items-center justify-between">
                <h3 class="text-sm font-semibold" v-tooltip="'Auditoria'">
                    {{ audit.hash }}
                </h3>
                <audit-status :audit="audit" @updated="alert(123)" />
            </div>
            <div class="mb-2">
                <h4
                    class="text-md flex items-center font-thin"
                    :class="{
                        'text-green-500': !audit.block_products,
                        'text-red-500': audit.block_products,
                    }"
                >
                    <component
                        :is="
                            !audit.block_products
                                ? 'CheckCircleIcon'
                                : 'NoSymbolIcon'
                        "
                        class="mr-1 h-6 w-6 focus-visible:ring-0"
                        aria-hidden="true"
                    />
                    Produtos

                    <button
                        @click.prevent="toggleProducts"
                        :disabled="loading || audit.status === 'closed'"
                        class="btn border-1 ml-auto border border-slate-500 bg-gray-800 px-2 py-1 text-xs text-slate-500 hover:bg-gray-700 disabled:cursor-not-allowed disabled:bg-gray-500 dark:text-white"
                    >
                        {{ !audit.block_products ? "Bloquear" : "Desbloquear" }}
                    </button>
                </h4>
            </div>

            <h4
                class="text-md flex items-center font-thin"
                :class="{
                    'text-green-500': !audit.block_cashout,
                    'text-red-500': audit.block_cashout,
                }"
            >
                <component
                    :is="
                        !audit.block_cashout
                            ? 'CheckCircleIcon'
                            : 'NoSymbolIcon'
                    "
                    class="mr-1 h-6 w-6 focus-visible:ring-0"
                    aria-hidden="true"
                />
                Saldo

                <button
                    @click.prevent="toggleBalance"
                    :disabled="loading || audit.status === 'closed'"
                    class="btn border-1 ml-auto border border-slate-500 bg-gray-800 px-2 py-1 text-xs text-slate-500 hover:bg-gray-700 disabled:cursor-not-allowed disabled:bg-gray-500 dark:text-white"
                >
                    {{ !audit.block_cashout ? "Bloquear" : "Desbloquear" }}
                </button>
            </h4>

            <audit-confirm-fraud :audit="audit" @fetch="$emit('fetch')" />
        </card>

        <div
            class="h-50 mb-4 flex w-full min-w-0 overflow-hidden rounded-lg bg-white p-5 drop-shadow-sm dark:bg-gray-800"
            v-if="showInteractions"
        >
            <timeline-skeleton v-if="loading" />
            <ol
                v-else
                class="relative w-full border-l border-gray-200 dark:border-gray-700"
            >
                <li
                    class="mb-10 ml-5"
                    v-for="interaction in audit.interactions"
                >
                    <dice-bear-avatar
                        v-if="interaction.user"
                        :seed="interaction.user.name"
                        class="absolute -left-3 h-6 w-6 rounded-full"
                    />

                    <div class="flex items-center justify-between">
                        <div>
                            <p
                                v-if="interaction.user"
                                class="flex text-xs font-thin text-gray-500 dark:text-gray-200"
                                v-tooltip="interaction.user.email"
                            >
                                {{ interaction.user.name }}
                            </p>
                            <h3
                                class="text-xs font-normal text-gray-900 dark:text-white"
                                v-if="
                                    interaction.interaction_type !== 'comment'
                                "
                            >
                                {{
                                    interactionName(
                                        interaction.interaction_type,
                                    )
                                }}
                            </h3>
                        </div>
                        <time
                            class="mb-1 text-right text-xs font-normal leading-none text-gray-200 text-gray-800 dark:text-gray-500"
                        >
                            {{ interaction.timestamp }}
                        </time>
                    </div>
                    <p
                        v-if="interaction.description"
                        class="mt-1 rounded-lg bg-gray-400 p-2 text-base text-sm font-normal text-white text-white dark:bg-slate-900 dark:text-gray-400"
                    >
                        <span
                            v-if="
                                interaction.interaction_type === 'notification'
                            "
                        >
                            {{ notificationName(interaction.description) }}
                        </span>
                        {{
                            interaction.interaction_type === "notification"
                                ? ""
                                : interaction.description
                        }}
                    </p>
                </li>
                <li class="ml-3">
                    <div
                        class="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-gray-200 dark:border-gray-900 dark:bg-gray-700"
                    ></div>

                    <div class="flex items-center justify-between">
                        <h3
                            class="text-sm font-light text-gray-900 dark:text-white"
                        >
                            Auditoria Iniciada
                        </h3>
                        <time
                            class="text-xs font-normal leading-none text-gray-900 dark:text-gray-500"
                        >
                            {{ audit.created_at }}
                        </time>
                    </div>
                    <p
                        v-if="audit.investigator"
                        class="mb-2 flex text-xs font-normal text-gray-500 dark:text-gray-200"
                    >
                        {{ audit.investigator.name }}
                        {{ audit.investigator.email }}
                    </p>
                    <p class="flex flex-col">
                        <small class="flex dark:text-white">
                            <boolean-icon
                                :boolean="!audit.block_products"
                                v-tooltip="
                                    audit.block_products
                                        ? 'Bloquear Produtos'
                                        : 'Não bloquear'
                                "
                            />
                            Produtos
                        </small>
                        <small class="flex dark:text-white">
                            <boolean-icon
                                :boolean="!audit.block_cashout"
                                v-tooltip="
                                    audit.block_cashout
                                        ? 'Bloquear Saques'
                                        : 'Não bloquear'
                                "
                            />
                            Saque
                        </small>
                    </p>
                    <p
                        class="text-base font-normal text-gray-500 dark:text-gray-200"
                    >
                        <a
                            @click="submit"
                            class="inline-flex hidden items-center rounded-lg border border-gray-200 bg-white px-4 py-2 text-sm font-medium text-gray-900 hover:cursor-pointer hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:outline-none focus:ring-4 focus:ring-gray-200 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-200 dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-gray-700"
                        >
                            Alterar Status
                            <svg
                                class="ml-2 h-3 w-3"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </a>
                    </p>
                </li>
            </ol>
        </div>
        <form @submit.prevent="comment" class="mb-3" v-if="showInteractions">
            <div
                class="flex flex-col rounded-lg bg-gray-400 pb-3 pr-2 pt-2 dark:bg-gray-800"
            >
                <textarea
                    v-model="form.description"
                    id="comment"
                    rows="2"
                    class="w-full resize-none appearance-none rounded-lg border-0 bg-transparent px-2 py-1 text-sm text-gray-900 placeholder:text-gray-100 focus:outline-none focus:ring-0 dark:text-white dark:placeholder-gray-400"
                    placeholder="Escreva um comentário..."
                    required
                ></textarea>

                <div class="flex justify-end">
                    <button
                        v-if="audit.status !== 'closed'"
                        @click.prevent="showFinishModal = true"
                        type="button"
                        class="inline-flex items-center rounded-lg bg-red-600 px-2 py-1 text-center text-xs font-bold font-medium text-white hover:bg-red-800 focus:ring-4 focus:ring-red-200 dark:focus:ring-red-900"
                    >
                        {{ form.description.length > 0 ? "Comentar e " : "" }}
                        Encerrar
                    </button>
                    <button
                        type="submit"
                        class="ml-1 inline-flex items-center rounded-lg bg-indigo-700 px-3 py-2 text-center text-xs font-bold text-white hover:bg-indigo-800 focus:ring-4 focus:ring-blue-200 dark:border-white dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white dark:focus:ring-blue-900 dark:focus:ring-gray-700"
                    >
                        Comentar
                    </button>
                </div>
            </div>
        </form>
        <modal :open="showFinishModal" @close="showFinishModal = false">
            <div class="mb-4 text-center">
                <h3 class="text-lg font-medium text-gray-900 dark:text-white">Confirmar Encerramento</h3>
                <div class="mt-2">
                    <p class="text-sm text-gray-500 dark:text-gray-300">
                        Ao encerrar esta auditoria, as seguintes ações serão realizadas automaticamente:
                    </p>
                    <div class="mt-3 flex justify-center">
                        <ul class="space-y-2 text-left">
                            <li v-if="audit.block_products" class="flex items-center">
                                <span class="mr-2 text-green-500">🔓</span>
                                <span class="text-sm dark:text-white">Produtos serão desbloqueados</span>
                            </li>
                            <li v-if="audit.block_cashout" class="flex items-center">
                                <span class="mr-2 text-green-500">💰</span>
                                <span class="text-sm dark:text-white">Saldo será liberado para saque</span>
                            </li>
                            <li class="flex items-center">
                                <span class="mr-2 text-red-500">🔒</span>
                                <span class="text-sm dark:text-white">Auditoria será encerrada</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            
            <div class="mt-5 flex justify-end space-x-2">
                <button 
                    @click="showFinishModal = false" 
                    class="inline-flex items-center rounded-lg border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600"
                >
                    Cancelar
                </button>
                <button 
                    @click="confirmFinish" 
                    class="inline-flex items-center rounded-lg bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
                >
                    Confirmar Encerramento
                </button>
            </div>
        </modal>
    </div>
</template>

<script>
import TimelineSkeleton from "../layouts/skeleton/TimelineSkeleton.vue";
import HelperMixin from "../../mixins/HelperMixin.vue";
import BooleanIcon from "../layouts/BooleanIcon.vue";
import Card from "../layouts/Card.vue";
import Modal from "../layouts/Modal.vue";
import AuditStatus from "./AuditStatus.vue";
import { CheckCircleIcon, NoSymbolIcon } from "@heroicons/vue/24/outline";
import SubmitButton from "../forms/SubmitButton.vue";
import AuditConfirmFraud from "./AuditConfirmFraud.vue";
import { notify } from "notiwind";
import DiceBearAvatar from "../common/DiceBearAvatar.vue";

export default {
    components: {
        DiceBearAvatar,
        AuditConfirmFraud,
        SubmitButton,
        NoSymbolIcon,
        CheckCircleIcon,
        AuditStatus,
        Card,
        BooleanIcon,
        TimelineSkeleton,
        Modal,
    },
    props: {
        audit: { default: () => {} },
        showCard: { default: () => false },
        showInteractions: { default: () => true },
    },
    mixins: [HelperMixin],
    emits: ["fetch"],
    data() {
        return {
            loading: false,
            approve: false,
            showFinishModal: false,
            form: {
                status: "open",
                description: "",
                type: "comment",
            },
        };
    },

    computed: {
        sortedInteractions() {
            if (!this.audit || !this.audit.interactions) {
                return [];
            }

            //sort by desc
            return this.audit.interactions;
        },
    },

    methods: {
        notify,
        confirmFinish() {
            this.finishAudit();
            this.showFinishModal = false;
        },
        finishAudit() {
            this.form.type = "closed";
            this.form.status = "closed";
            this.submit().then(() => {
                this.form.description = "";
            });
        },
        toggleProducts() {
            this.form.type = this.audit.block_products
                ? "unblockProducts"
                : "blockProducts";
            this.submit().then(() => {
                this.form.description = "";
            });
        },
        toggleBalance() {
            this.form.type = this.audit.block_cashout
                ? "unblockCashout"
                : "blockCashout";
            this.submit().then(() => {
                this.form.description = "";
            });
        },
        comment() {
            this.form.type = "comment";
            this.submit().then(() => {
                this.form.description = "";
            });
        },
        submit() {
            this.loading = true;
            return axios
                .post("/api/audit/" + this.audit.id + "/interaction", this.form)
                .then(() => {
                    this.$emit("fetch");
                    this.loading = false;
                });
        },
        notificationName(name) {
            switch (name) {
                case "App\\Notifications\\User\\Audit\\RefundNeeded":
                    return "Reembolso Requerido";
                case "App\\Notifications\\User\\Audit\\AccountBlocked":
                    return "Conta Bloqueada";
                default:
                    return name;
            }
        },
        interactionName(name) {
            switch (name) {
                case "blockProducts":
                    return "Produtos Bloqueados";
                case "unblockProducts":
                    return "Produtos Desbloqueados";
                case "blockCashout":
                    return "Saldo Bloqueado";
                case "unblockCashout":
                    return "Saldo Desbloqueado";
                case "comment":
                    return "Comentário";
                case "closed":
                    return "Finalizado";
                case "notification":
                    return "Notificação";
                default:
                    return name;
            }
        },
        interactionClass(name) {
            switch (name) {
                case "comment":
                    return "bg-blue-400 text-white";
                case "closed":
                    return "bg-red-400 text-white";
                default:
                    return name;
            }
        },
    },
};
</script>
