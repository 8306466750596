<template>
  <card class="dark:text-white">
    <div class="card-header flex justify-between items-center">
      <h5 class="mb-0">Batches de Auditoria</h5>
      <div class="flex gap-2">
        <button 
          class="btn btn-primary" 
          @click="startNewBatch"
          :disabled="loading"
        >
          Iniciar Novo Batch
        </button>
      </div>
    </div>
    
    <div class="card-body">
      <!-- Filtros -->
      <div class="flex items-center" v-show="false">
        <div >
          <label class="form-label">Status</label>
          <multiple-select
            :options="[
              { label: 'Todos', value: '' },
              { label: 'Pendente', value: 'pending' },
              { label: 'Processando', value: 'processing' },
              { label: 'Concluído', value: 'completed' },
              { label: 'Cancelado', value: 'cancelled' },
              { label: 'Falhou', value: 'failed' }
            ]"
            v-model="filters.status"
            placeholder="Status"
          />
        </div>
        <div class="col-md-4">
          <label class="form-label">Período</label>
          <litepie-datepicker
            v-model="dateRange"
            :formatter="{
              date: 'DD/MM/YYYY',
              month: 'MMM',
            }"
            :shortcuts="false"
            @input="onDateChange"
          />
        </div>
      </div>
      <table-component 
        :headers="[
          'ID',
          'Status',
          'Progresso', 
          'Total Jobs',
          'Processados',
          'Falhas',
          'Criado por',
          'Data Início',
          'Data Fim',
          'Ações'
        ]"
        :pagination="batches"
        :loading="loading"
        @fetch="loadBatches"
      >
        <tr v-for="batch in batches.data" :key="batch.id">
          <td class="table-cell-sm">
            <div class="flex items-center">
              #{{ batch.id }}
              <svg
                v-if="batch.status === 'processing'"
                class="ml-2 h-5 w-5 animate-spin text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                >
                <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
                ></circle>
                <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          </td>
          <td class="table-cell-sm">
            <span :class="getStatusClass(batch.status)">
              {{ getStatusLabel(batch.status) }}
            </span>
          </td>
          <td class="table-cell-sm">
            <div class="progress" style="height: 20px">
              <div 
                class="progress-bar" 
                :class="getProgressClass(batch.status)"
                :style="{ width: batch.progress + '%' }"
              >
                {{ batch.progress.toFixed(1) }}%
              </div>
            </div>
          </td>
          <td class="table-cell-sm">{{ batch.total_jobs }}</td>
          <td class="table-cell-sm">{{ batch.processed_jobs }}</td>
          <td class="table-cell-sm">{{ batch.failed_jobs }}</td>
          <td class="table-cell-sm">{{ batch.creator.name }}</td>
          <td class="table-cell-sm">{{ batch.started_at || '-' }}</td>
          <td class="table-cell-sm">{{ batch.completed_at || '-' }}</td>
          <td class="table-cell-sm">
             <button 
              class="btn btn-sm btn-outline-primary"
              @click="startPolling(batch)"
              :disabled="loading"
            >
              Polling
            </button>
            <button 
              v-if="canCancel(batch)"
              class="btn btn-sm btn-danger"
              @click="cancelBatch(batch)"
              :disabled="loading"
            >
              Cancelar
            </button>
          </td>
        </tr>
      </table-component>
      <!-- Paginação -->
      <div class="d-flex justify-content-end mt-4">
        <pagination 
          :data="batches"
          @pagination-change-page="loadBatches"
        />
      </div>
    </div>
  </card>
</template>

<script>
import { ref, onMounted, watch } from 'vue'
import moment from 'moment'
import axios from 'axios'
import { useToast } from 'vue-toastification'
import Card from '@/components/layouts/Card.vue'
import TableComponent from '@/components/layouts/table/TableComponent.vue'
import MultipleSelect from '@/components/forms/MultipleSelect.vue'
export default {
  components: {
    Card,
    TableComponent,
    MultipleSelect
  },
  setup() {
    const toast = useToast()
    const loading = ref(false)
    const batches = ref({
      data: [],
      meta: {}
    })
    const filters = ref({
      status: [],
      date_range: ''
    })

    watch(() => filters.value.status, (newStatus) => {
      console.log({newStatus})
      loadBatches()
    });
    const dateRange = ref([])

    const loadBatches = async (page = 1) => {
      try {
        loading.value = true
        const params = { 
          ...filters.value,
          status: filters.value.status[0] || '',
          page 
        }
        
        const { data } = await axios.get('/api/audit-batches', { params })
        batches.value.data = data.data
        batches.value.meta = data.meta
      } catch (error) {
        toast.error('Erro ao carregar batches')
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    const startPolling = async (batch) => {
      try {
        console.log('startPolling ' + batch.id)
        const interval = setInterval(async () => {
          const { data } = await axios.get(`/api/audit-batches/${batch.id}`)
          if (data.status === 'completed') {
            clearInterval(interval)
          }
          batches.value.data = batches.value.data.map(batch => batch.id === data.data.id ? data.data : batch)
          console.log({data})
        }, 5000)
      } catch (error) {
        console.error(error)
      }
    }

    const startNewBatch = async () => {
      try {
        loading.value = true
        const { data } = await axios.post('/api/audit-batches')
        startPolling(data);
        toast.success('Batch iniciado com sucesso')
        loadBatches()
      } catch (error) {
        if (error.response?.status === 429) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Erro ao iniciar batch')
        }
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    const cancelBatch = async (batch) => {
      try {
        loading.value = true
        await axios.post(`/api/audit-batches/${batch.id}/cancel`)
        toast.success('Batch cancelado com sucesso')
        loadBatches()
      } catch (error) {
        toast.error('Erro ao cancelar batch')
        console.error(error)
      } finally {
        loading.value = false
      }
    }

    const onDateChange = (dates) => {
      if (!dates || dates.length !== 2) {
        filters.value.date_range = ''
      } else {
        const start = moment(dates[0]).format('YYYY-MM-DD')
        const end = moment(dates[1]).format('YYYY-MM-DD')
        filters.value.date_range = `${start},${end}`
      }
      loadBatches()
    }

    const getStatusClass = (status) => {
      const classes = {
        pending: 'badge bg-warning',
        processing: 'badge bg-info',
        completed: 'badge bg-success',
        cancelled: 'badge bg-danger',
        failed: 'badge bg-danger'
      }
      return classes[status] || 'badge bg-secondary'
    }

    const getProgressClass = (status) => {
      const classes = {
        pending: 'bg-warning',
        processing: 'bg-info progress-bar-striped progress-bar-animated',
        completed: 'bg-success',
        cancelled: 'bg-danger',
        failed: 'bg-danger'
      }
      return classes[status] || 'bg-secondary'
    }

    const getStatusLabel = (status) => {
      const labels = {
        pending: 'Pendente',
        processing: 'Processando',
        completed: 'Concluído',
        cancelled: 'Cancelado',
        failed: 'Falhou'
      }
      return labels[status] || status
    }

    const canCancel = (batch) => {
      return ['pending', 'processing'].includes(batch.status)
    }

    onMounted(() => {
      loadBatches()
    })

    Echo.channel('audit.batches')
      .listen('.App\\Events\\Audit\\BatchStateUpdated', (e) => {
        batches.value.data = batches.value.data.map(batch => batch.id === e.batch.id ? e.batch : batch)
      })

    return {
      startPolling,
      loading,
      batches,
      filters,
      dateRange,
      loadBatches,
      startNewBatch,
      cancelBatch,
      onDateChange,
      getStatusClass,
      getProgressClass,
      getStatusLabel,
      canCancel
    }
  }
}
</script>

<style scoped>
.dp__theme_light {
  --dp-background-color: #fff;
  --dp-text-color: #212121;
  --dp-hover-color: #f3f3f3;
  --dp-hover-text-color: #212121;
  --dp-hover-icon-color: #959595;
  --dp-primary-color: #1976d2;
  --dp-primary-text-color: #f8f9fa;
  --dp-secondary-color: #c0c4cc;
  --dp-border-color: #ddd;
  --dp-menu-border-color: #ddd;
  --dp-border-color-hover: #aaaeb7;
  --dp-disabled-color: #f6f6f6;
  --dp-scroll-bar-background: #f3f3f3;
  --dp-scroll-bar-color: #959595;
  --dp-success-color: #76d275;
  --dp-success-color-disabled: #a3d9b1;
  --dp-icon-color: #959595;
  --dp-danger-color: #ff6f60;
}
</style> 