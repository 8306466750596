<template>
    <submit-button class="ml-2 text-sm" size="sm" @click.prevent="show = true"
        >Adicionar</submit-button
    >
    <modal
        class="min-h-full"
        :open="Boolean(banner) || show"
        @close="
            show = false;
            $emit('resetNotice');
        "
    >
        <div
            class="flex min-h-fit flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        ></div>
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            {{ banner ? "Editar" : "Adicionar" }} Banner

            <button
                class="ml-auto rounded-lg bg-red-500 px-2 py-1"
                size="sm"
                @click.prevent="
                    show = false;
                    $emit('resetNotice');
                "
            >
                Fechar
            </button>
        </h1>

        <banner-form
            @fetch="
                show = false;
                $emit('fetch');
                $emit('resetNotice');
            "
            :banner="banner"
        />
    </modal>
</template>

<script>
import Modal from "../../layouts/Modal.vue";
import SubmitButton from "../../forms/SubmitButton.vue";
import BannerForm from "../BannerForm.vue";

export default {
    props: { banner: { type: Object, default: null } },
    components: { BannerForm, SubmitButton, Modal },
    emits: ["fetch"],
    data() {
        return {
            show: false,
        };
    },
};
</script>
