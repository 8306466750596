<template>
    <h3
        class="font-large item-center my-3 flex text-xl leading-6 dark:text-white"
    >
        Banners
        <banner-modal
            v-if="hasPermission('create.notices')"
            @fetch="fetch"
            :banner="banner"
            @resetNotice="banner = null"
        />
    </h3>

    <ticto-loading v-if="loading" />
    <filters
        class="my-3"
        :loading="loading"
        v-model="filters"
        type-label="Tipo"
        :types="types"
        search-filter
        search-label="Filtre pelo título ou link"
        @fetch="
            products = [];
            next_page_url = false;
            this.pagination.current_page = 1;
            fetch();
        "
    >
    </filters>
    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="headers"
        @fetch="fetch"
        :selected="selected"
        :all-selected="all_selected"
    >
        <tr v-for="banner in banners" :key="banner.id">
            <td class="table-cell-sm">
                <span
                    :title="banner.title"
                    class="block"
                    v-tooltip="banner.title"
                >
                    {{ banner.title }}
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    :title="banner.redirect_link"
                    class="block"
                    v-tooltip="banner.redirect_link"
                >
                    {{ banner.redirect_link }}
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    :title="banner.begin_at"
                    class="block"
                    v-tooltip="banner.begin_at"
                >
                    {{ banner.begin_at }}
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    :title="banner.end_at"
                    class="block"
                    v-tooltip="banner.end_at"
                >
                    {{ banner.end_at }}
                </span>
            </td>
            <td class="table-cell-sm">
                <toggle-banner
                    v-if="hasPermission('update.notices')"
                    :banner="banner"
                    @fetch="fetch"
                ></toggle-banner>
            </td>
            <td>
                <div>
                    <button
                        v-if="hasPermission('update.notices')"
                        @click.prevent="openModalToEdit(banner)"
                        :disabled="loading"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="ml-2 inline-flex items-center justify-center rounded-md bg-yellow-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                    >
                        <PencilSquareIcon
                            class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                        />
                    </button>
                    <button
                        v-if="hasPermission('delete.notices')"
                        @click.prevent="handleDeleteBanner(banner.id)"
                        :disabled="loading"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="ml-2 inline-flex items-center justify-center rounded-md bg-red-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-red-400"
                    >
                        <TrashIcon
                            class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                        />
                    </button>
                </div>
            </td>
        </tr>
    </table-component>
</template>

<script>
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import UsersSelect from "../../components/forms/UsersSelect.vue";
import VueSelect from "../../components/forms/VueSelect.vue";
import Manager from "../../components/manager/Manager.vue";
import Filters from "../../components/layouts/Filters.vue";
import permissionMixin from "../../mixins/PermissionMixin.vue";
import ToggleBanner from "../../components/notices/ToggleBanner.vue";
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import BannerModal from "../../components/notices/modals/BannerModal.vue";
import EmptyState from "../../components/layouts/EmptyState.vue";
import { useToast } from "vue-toastification";
import { PencilSquareIcon, TrashIcon } from "@heroicons/vue/24/outline";

export default {
    components: {
        EmptyState,
        Filters,
        BannerModal,
        Manager,
        ToggleBanner,
        VueSelect,
        UsersSelect,
        TableComponent,
        TictoLoading,
        PencilSquareIcon,
        TrashIcon,
    },
    mixins: [permissionMixin],
    data() {
        return {
            banners: [],
            next_page_url: false,
            types: [{ label: "Padrão", value: "default" }],
            selected: [],
            all_selected: false,
            products: [],
            types: [],
            banner: null,
            pagination: {
                total: 10,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                types: [],
                date: [],
            },
            loading: false,
        };
    },

    setup() {
        const toast = useToast();
        return { toast };
    },

    mounted() {
        this.fetch();
    },

    computed: {
        headers() {
            return [
                "Titulo",
                "Link",
                "Exibir a partir de",
                "Exibir até",
                "Ativo",
                "Ações",
            ];
        },
    },

    methods: {
        fetch() {
            let params = this.filters;
            params["page"] = this.pagination.current_page;

            this.loading = true;

            axios
                .get("/api/banners", {
                    params: params,
                })
                .then(({ data }) => {
                    this.banners = data.data;
                    this.pagination = {
                        ...this.pagination,
                        ...data.links,
                        ...data.meta,
                    };

                    this.loading = false;
                });
        },
        openModalToEdit(banner) {
            this.banner = banner;
        },
        handleDeleteBanner(id) {
            if (confirm("Tem certeza que deseja excluir este banner?")) {
                axios
                    .delete(`/api/banners/${id}`)
                    .then(({ data }) => {
                        if (data ?? null) {
                            this.toast.success("Banner excluido com sucesso");
                            this.fetch();
                        }
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => {
                            this.toast.error(message);
                        },
                    );
            }
        },
        typeTranslation(type) {
            let typeTranslations = {
                default: "Padrão",
            };

            return typeTranslations[type];
        },
    },
};
</script>
