<template>
    <label
        :for="randomId"
        class="relative mb-4 inline-flex cursor-pointer items-center"
    >
        <input
            :checked="modelValue"
            @change="$emit('update:modelValue', $event.target.checked)"
            type="checkbox"
            :id="randomId"
            class="peer sr-only"
            :disabled="loading"
        />
        <div
            class="peer h-6 w-11 rounded-full border border-gray-400 bg-gray-200 after:absolute after:left-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:ring-4 peer-focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"
        ></div>
        <span
            :class="labelColor"
            class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300"
            >{{ label }}</span
        >
    </label>
</template>

<script>
export default {
    props: ["modelValue", "loading", "label", "labelColor"],
    emits: ["update:modelValue"],
    data() {
        return {
            randomId: Math.random().toString(36).substr(2, 9),
        };
    },
};
</script>
