<template>
    <div>
        <h3
            class="font-large item-center my-3 flex text-xl leading-6 dark:text-white"
        >
            Auditoria
            <store-modal
                @fetch="
                    audits = [];
                    page = 1;
                    fetch();
                "
            />
        </h3>

        <filters
            v-model="filters"
            :loading="loading"
            date-filter
            search-filter
            status-filter
            :statuses="[
                { value: 'open', label: 'Iniciado' },
                { value: 'closed', label: 'Finalizado' },
                { value: 'contested', label: 'Contestado' },
            ]"
            @fetch="
                page = 1;
                fetch();
            "
        />

        <div class="mb-3 flex">
            <div class="ml-auto flex items-center dark:text-white">
                <button
                    class="btn-primary-sm ml-auto mr-2"
                    :disabled="loading"
                    @click.prevent="exportAudits"
                >
                    <ArrowDownTrayIcon
                        class="mr-1.5 h-5 w-5 flex-shrink-0 text-white"
                        aria-hidden="true"
                    />
                    Exportar
                </button>

                Exibindo {{ pagination.from }} a {{ pagination.to }} registros
                de
                {{ pagination.total }}
            </div>
        </div>

        <ticto-loading v-if="loading && !audits.length" />
        <div class="not-prose relative rounded rounded-xl">
            <empty-state v-if="!loading && !audits.length" />
            <ul role="list" class="divide-y dark:divide-slate-700">
                <audit
                    v-for="(audit, index) in audits"
                    :key="audit.id"
                    :audit="audit"
                    :class="{
                        'rounded-t-lg': index === 0,
                        'rounded-b-lg': index === audits.length - 1,
                    }"
                    @fetch="fetch"
                />
            </ul>

            <div class="my-3 flex justify-center">
                <pagination
                    :pagination="pagination"
                    @paginate="paginate($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import StoreModal from "../../components/audit/modals/StoreModal.vue";
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import Manager from "../../components/manager/Manager.vue";
import EmptyState from "../../components/layouts/EmptyState.vue";
import Audit from "../../components/audit/Audit.vue";
import Filters from "../../components/layouts/Filters.vue";
import SubmitButton from "../../components/forms/SubmitButton.vue";
import { ArrowDownTrayIcon } from "@heroicons/vue/24/outline";
import Pagination from "../../components/layouts/Pagination.vue";
export default {
    components: {
        Pagination,
        ArrowDownTrayIcon,
        SubmitButton,
        Filters,
        EmptyState,
        Manager,
        TictoLoading,
        StoreModal,
        Audit,
    },
    data() {
        return {
            loading: false,
            audits: [],
            filters: {},
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            page: 1,
        };
    },

    mounted() {
        this.fetch();
    },

    methods: {
        exportAudits() {
            this.loading = true;
            axios
                .get("/api/audit/export", {
                    params: this.filters,
                })
                .then(({ data }) => {
                    this.loading = false;
                });
        },
        fetch() {
            this.loading = true;

            if (this.page === 1) {
                this.audits = [];
            }

            let filters = {
                ...this.filters,
                page: this.page,
            };

            axios
                .get("/api/audit", {
                    params: {
                        ...filters,
                        page: this.page,
                    },
                })
                .then(({ data }) => {
                    this.audits = data.data;

                    if (this.page >= data.meta.last_page) {
                        this.page = false;
                    }

                    this.pagination.total = data.meta.total;
                    this.pagination.per_page = data.meta.per_page;
                    this.pagination.current_page = data.meta.current_page;
                    this.pagination.last_page = data.meta.last_page;
                    this.pagination.from = data.meta.from;
                    this.pagination.to = data.meta.to;

                    this.loading = false;
                });
        },
        paginate(page) {
            this.pagination.current_page = page;
            this.page = page;
            this.fetch();
        },
    },
};
</script>
