<template>
    <h3
        class="font-large item-center my-3 flex text-xl leading-6 dark:text-white"
    >
        Avisos
        <store-modal
            v-if="hasPermission('create.notices')"
            @fetch="fetch"
            :notice="notice"
            @resetNotice="notice = null"
        />
    </h3>

    <ticto-loading v-if="loading" />
    <filters
        class="my-3"
        :loading="loading"
        v-model="filters"
        type-label="Tipo"
        :types="types"
        search-filter
        search-label="Filtre pelo título, mensagem ou link"
        @fetch="
            products = [];
            next_page_url = false;
            this.pagination.current_page = 1;
            fetch();
        "
    >
    </filters>
    <table-component
        :loading="loading"
        :pagination="pagination"
        :headers="headers"
        @fetch="fetch"
        :selected="selected"
        :all-selected="all_selected"
    >
        <tr v-for="notice in notices">
            <td class="table-cell-sm">
                <span
                    :title="notice.title"
                    class="block max-w-[80px] truncate"
                    v-tooltip="notice.title"
                >
                    {{ notice.title }}
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    :title="notice.message"
                    v-html="notice.message"
                    class="block max-w-[80px] truncate"
                    v-tooltip="notice.message"
                >
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    :title="notice.type"
                    class="block max-w-[80px] truncate"
                    v-tooltip="notice.type"
                >
                    {{ typeTranslation(notice.type) }}
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    :title="notice.link"
                    class="block max-w-[80px] truncate"
                    v-tooltip="notice.link"
                >
                    {{ notice.link }}
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    :title="notice.begin_at"
                    class="block max-w-[80px] truncate"
                    v-tooltip="notice.begin_at"
                >
                    {{ notice.begin_at }}
                </span>
            </td>
            <td class="table-cell-sm">
                <span
                    :title="notice.end_at"
                    class="block max-w-[80px] truncate"
                    v-tooltip="notice.end_at"
                >
                    {{ notice.end_at }}
                </span>
            </td>
            <td class="table-cell-sm">
                <toggle-notice
                    v-if="hasPermission('update.notices')"
                    :notice="notice"
                    @fetch="fetch"
                ></toggle-notice>
                <span
                    v-else
                    :title="notice.is_active"
                    class="block max-w-[80px] truncate"
                    v-tooltip="notice.is_active"
                >
                    {{ notice.is_active ? "Ativo" : "Inativo" }}
                </span>
            </td>
            <td>
                <div>
                    <button
                        @click.prevent="openModalToEdit(notice)"
                        v-if="hasPermission('update.notices')"
                        :disabled="loading"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="ml-2 inline-flex items-center justify-center rounded-md bg-indigo-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-indigo-400"
                    >
                        Editar
                    </button>
                    <button
                        @click.prevent="handleDeleteNotice(notice.id)"
                        v-if="hasPermission('delete.notices')"
                        :disabled="loading"
                        type="button"
                        :class="{ 'cursor-not-allowed': loading }"
                        class="ml-2 inline-flex items-center justify-center rounded-md bg-red-500 px-2 py-1 text-sm font-semibold leading-6 text-white shadow transition duration-150 ease-in-out hover:bg-red-400"
                    >
                        Excluir
                    </button>
                </div>
            </td>
        </tr>
    </table-component>
</template>

<script>
import TictoLoading from "../../components/layouts/TictoLoading.vue";
import UsersSelect from "../../components/forms/UsersSelect.vue";
import VueSelect from "../../components/forms/VueSelect.vue";
import Manager from "../../components/manager/Manager.vue";
import Filters from "../../components/layouts/Filters.vue";
import permissionMixin from "../../mixins/PermissionMixin.vue";
import ToggleNotice from "../../components/notices/ToggleNotice.vue";
import TableComponent from "../../components/layouts/table/TableComponent.vue";
import StoreModal from "../../components/notices/modals/StoreModal.vue";
import EmptyState from "../../components/layouts/EmptyState.vue";
import { useToast } from "vue-toastification";
export default {
    components: {
        EmptyState,
        Filters,
        StoreModal,
        Manager,
        ToggleNotice,
        VueSelect,
        UsersSelect,
        TableComponent,
        TictoLoading,
    },
    mixins: [permissionMixin],
    data() {
        return {
            notices: [],
            next_page_url: false,
            types: [{ label: "Padrão", value: "default" }],
            selected: [],
            all_selected: false,
            products: [],
            types: [],
            notice: null,
            pagination: {
                total: 0,
                per_page: 10,
                current_page: 1,
                last_page: 11,
                from: 1,
                to: 11,
            },
            filters: {
                text: "",
                types: [],
                date: [],
            },
        };
    },

    setup() {
        const toast = useToast();
        return { toast };
    },

    mounted() {
        this.fetch();
    },

    computed: {
        headers() {
            return [
                "Titulo",
                "Mensagem",
                "Tipo",
                "Link",
                "Exibir a partir de",
                "Exibir até",
                "Ativo",
                "Ações",
            ];
        },
    },

    methods: {
        fetch() {
            let params = this.filters;
            params["page"] = this.pagination.current_page;

            this.loading = true;

            axios
                .get("/api/notices", {
                    params: params,
                })
                .then(({ data }) => {
                    this.notices = data.data;

                    this.pagination = {
                        ...this.pagination,
                        ...data.links,
                        ...data.meta,
                    };

                    this.loading = false;
                });
        },
        openModalToEdit(notice) {
            this.notice = notice;
        },
        handleDeleteNotice(id) {
            if (confirm("Tem certeza que deseja excluir este aviso?")) {
                axios
                    .delete(`/api/notices/${id}`)
                    .then(({ data }) => {
                        if (data ?? null) {
                            this.toast.success("Aviso excluido com sucesso");
                            this.fetch();
                        }
                    })
                    .catch(
                        ({
                            response: {
                                data: { message },
                            },
                        }) => {
                            this.toast.error(message);
                        },
                    );
            }
        },
        typeTranslation(type) {
            let typeTranslations = {
                default: "Padrão",
            };

            return typeTranslations[type];
        },
    },
};
</script>
