<template>
    <div class="flex flex-col">
        <h3
            class="align-center mb-2 flex align-bottom text-lg font-bold text-gray-400"
        >
            <span>Score</span>
            <span
                v-if="updating"
                class="ml-2 flex animate-pulse items-center rounded bg-gray-600 px-2 align-middle text-xs text-white dark:bg-gray-200 dark:text-gray-800"
                :class="{
                    '': loading,
                }"
            >
                Atualizando
            </span>

            <div class="ml-auto flex items-center">
                <div v-if="canManageBypass" class="mr-4 flex items-center">
                    <span class="mr-2">Bypass</span>
                    <label class="relative inline-flex cursor-pointer items-center">
                        <input 
                            type="checkbox" 
                            class="peer sr-only" 
                            :checked="bypassActive"
                            @change="toggleBypass"
                            :disabled="loadingBypass"
                        >
                        <div class="h-6 w-11 rounded-full bg-gray-200 after:absolute after:left-[2px] after:top-[2px] after:h-5 after:w-5 after:rounded-full after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                    </label>
                </div>
                <p v-if="score" class="mr-1">
                    Atualizado em: {{ score.created_at }}
                </p>
                <submit-button
                    size="sm"
                    :loading="loading"
                    :disabled="loading || updating"
                    @click="store"
                >
                    Calcular
                </submit-button>
            </div>
        </h3>

        <ticto-loading v-if="loading" />
        <template v-else>
            <div class="grid grid-cols-3 gap-2" v-if="score">
                <div>
                    <score-gauge :score="score.amount"/>
                </div>

                <score-table v-if="score" class="col-span-2" :score="score" />
            </div>
            <score-evolution :user="user" class="mt-4" />
        </template>
    </div>
</template>

<script>
import SubmitButton from "../forms/SubmitButton.vue";
import TictoLoading from "../layouts/TictoLoading.vue";
import Card from "../layouts/Card.vue";
import ScoreGauge from "./score/ScoreGauge.vue";
import ScoreTable from "./score/ScoreTable.vue";
import ScoreEvolution from "./score/ScoreEvolution.vue";
import { Store } from "../../stores/store";
import { mapWritableState } from "pinia";

export default {
    props: ["user"],
    components: {
        ScoreTable,
        Card,
        TictoLoading,
        SubmitButton,
        ScoreGauge,
        ScoreEvolution,
    },
    data() {
        return {
            updating: false,
            loading: true,
            loadingBypass: false,
            seller: false,
            score: false,
            bypassActive: false,
        };
    },

    computed: {
        ...mapWritableState(Store, ["authUser"]),
        canManageBypass() {
            return this.authUser && this.authUser.id === 2;
        },
    },

    mounted() {
        this.fetch();
        this.checkBypassStatus();

        Echo.channel("user-score-" + this.user.id).listen(
            ".App\\Events\\Score\\UpdatingScore",
            (e) => {
                this.updating = e.updating;

                if (!e.updating) {
                    this.fetch();
                }
            },
        );
    },

    methods: {
        fetch() {
            this.loading = true;

            axios
                .get("/api/user/" + this.user.id + "/score")
                .then(({ data }) => {
                    this.seller = data.seller;
                    this.score = data.score;
                    this.loading = false;
                });
        },
        store() {
            this.loading = true;

            axios
                .post("/api/user/" + this.user.id + "/score")
                .then(({ data }) => {
                    this.loading = false;
                    this.fetch();
                });
        },
        checkBypassStatus() {
            if (!this.canManageBypass) return;
            
            axios
                .get(`/api/user/${this.user.id}/score/bypass`)
                .then(({ data }) => {
                    this.bypassActive = data.active;
                })
                .catch(error => {
                    console.error("Erro ao verificar status do bypass:", error);
                });
        },
        toggleBypass() {
            if (!this.canManageBypass) return;
            
            this.loadingBypass = true;
            
            axios
                .post(`/api/user/${this.user.id}/score/bypass/toggle`)
                .then(({ data }) => {
                    this.bypassActive = data.active;
                    this.$toasted.show(
                        data.active 
                            ? "Bypass de score ativado com sucesso!" 
                            : "Bypass de score desativado com sucesso!",
                        { type: "success" }
                    );
                })
                .catch(error => {
                    console.error("Erro ao alternar bypass:", error);
                    this.$toasted.show(
                        "Erro ao alternar bypass de score.",
                        { type: "error" }
                    );
                })
                .finally(() => {
                    this.loadingBypass = false;
                });
        },
    },
};
</script>
