<template>
    <transition-root as="template" v-if="open" :show="open">
        <dialog-headless
            as="div"
            class="fixed inset-0 z-30 overflow-y-auto"
            @close="$emit('close')"
        >
            <div
                class="flex min-h-screen items-end pb-20 text-center sm:block sm:p-0"
            >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <dialog-overlay
                        class="fixed inset-0 bg-gray-600 bg-opacity-75 transition-opacity"
                    />
                </transition-child>
                <span
                    class="hidden sm:inline-block sm:h-screen sm:align-middle"
                    aria-hidden="true"
                    >&#8203;</span
                >
                <transition-child
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <div
                        class="z-40 inline-block transform rounded-lg bg-gray-700 text-left align-bottom shadow-xl transition-all sm:my-8 sm:align-top"
                    >
                        <div>
                            <div class="mt-3 text-center sm:mt-0 sm:text-left">
                                <div class="my-3 px-3">
                                    <slot></slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-child>
            </div>
        </dialog-headless>
    </transition-root>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from "@headlessui/vue";
export default {
    props: {
        open: { type: Boolean, default: false },
        size: { type: String, default: "md" },
    },

    components: {
        DialogHeadless: Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
    },
};
</script>
