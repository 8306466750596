<template>
    <div class="p-6 space-y-6">
        <!-- Métricas de Fraude -->
        <div class="grid grid-cols-4 gap-4">
            <MetricCard
                title="Fraudes (30d)"
                :value="formatCurrency(metrics.fraud_metrics.total_amount)"
                :subtitle="`${metrics.fraud_metrics.total_count} transações`"
                trend="danger"
            />
            <MetricCard
                title="Usuários Críticos"
                :value="metrics.risk_indicators.critical_score_users"
                subtitle="Score <= 25"
                trend="warning"
            />
            <MetricCard
                title="Alta Taxa CC"
                :value="metrics.risk_indicators.high_cc_rate_users"
                subtitle=">80% em cartão"
                trend="warning"
            />
        </div>

        <!-- Métricas de Chargeback -->
        <div class="bg-white dark:bg-slate-800 rounded-lg shadow p-4">
            <h2 class="text-lg font-semibold text-gray-900 dark:text-white mb-4">Métricas de Chargeback (30d)</h2>
            <div class="grid grid-cols-3 gap-4">
                <div class="space-y-4">
                    <MetricCard
                        title="Chargeback Total"
                        :value="`${metrics.chargeback_metrics.chargeback_rate_total}%`"
                        :subtitle="`${metrics.chargeback_metrics.chargeback_total} / ${metrics.chargeback_metrics.approved_total} transações`"
                        trend="danger"
                    />
                </div>
                <div class="space-y-4">
                    <MetricCard
                        title="Com Afiliados"
                        :value="`${metrics.chargeback_metrics.chargeback_rate_with_affiliates}%`"
                        :subtitle="`${metrics.chargeback_metrics.chargeback_with_affiliates} / ${metrics.chargeback_metrics.approved_with_affiliates} transações`"
                        trend="danger"
                    />
                </div>
                <div class="space-y-4">
                    <MetricCard
                        title="Sem Afiliados"
                        :value="`${metrics.chargeback_metrics.chargeback_rate_without_affiliates}%`"
                        :subtitle="`${metrics.chargeback_metrics.chargeback_without_affiliates} / ${metrics.chargeback_metrics.approved_without_affiliates} transações`"
                        trend="danger"
                    />
                </div>
            </div>
        </div>

        <!-- Últimos Bloqueios -->
        <div class="bg-white dark:bg-slate-800 rounded-lg shadow">
            <div class="p-4 border-b border-gray-200 dark:border-gray-700">
                <h2 class="text-lg font-semibold text-gray-900 dark:text-white">Últimos Bloqueios (7d)</h2>
            </div>
            <div class="p-4">
                <table class="min-w-full">
                    <thead>
                        <tr>
                            <th class="text-left text-sm font-medium text-gray-500 dark:text-gray-400">Email</th>
                            <th class="text-left text-sm font-medium text-gray-500 dark:text-gray-400">Score</th>
                            <th class="text-left text-sm font-medium text-gray-500 dark:text-gray-400">Taxa CC</th>
                            <th class="text-left text-sm font-medium text-gray-500 dark:text-gray-400">Chargeback</th>
                            <th class="text-left text-sm font-medium text-gray-500 dark:text-gray-400">Diversidade BIN</th>
                            <th class="text-left text-sm font-medium text-gray-500 dark:text-gray-400">Bounces</th>
                            <th class="text-left text-sm font-medium text-gray-500 dark:text-gray-400">Data</th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 dark:divide-gray-700">
                        <tr v-for="user in metrics.blocked_users" :key="user.id" 
                            class="text-gray-900 dark:text-gray-300">
                            <td>{{ user.email }}</td>
                            <td :class="getScoreClass(user.score)">
                                {{ user.score }}
                            </td>
                            <td>{{ user.credit_card_transaction_rate }}%</td>
                            <td>{{ user.chargeback_rate }}%</td>
                            <td>{{ user.bin_diversity_rate }}%</td>
                            <td>{{ user.email_bounces }}</td>
                            <td>{{ formatDate(user.blocked_at) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Indicadores de Risco -->
        <div class="grid grid-cols-3 gap-4">
            <RiskCard
                title="Alta Taxa de Chargeback"
                :value="metrics.risk_indicators.high_chargeback_users"
                subtitle="≥10% de chargebacks"
                icon="ExclamationTriangleIcon"
            />
            <RiskCard
                title="Baixa Diversidade BIN"
                :value="metrics.risk_indicators.low_bin_diversity_users"
                subtitle="≤20% diversidade"
                icon="ShieldExclamationIcon"
            />
            <RiskCard
                title="Alto Bounce Rate"
                :value="metrics.risk_indicators.high_bounce_users"
                subtitle="≥20 bounces"
                icon="EnvelopeIcon"
            />
        </div>
    </div>
</template>

<script>
import MetricCard from '../../components/MetricCard.vue'
import RiskCard from '../../components/RiskCard.vue'

export default {
    components: {
        MetricCard,
        RiskCard
    },
    data() {
        return {
            metrics: {
                fraud_metrics: {},
                chargeback_metrics: {},
                blocked_users: [],
                risk_indicators: {}
            }
        }
    },
    methods: {
        getScoreClass(score) {
            return {
                'text-red-500 dark:text-red-400': score <= 25,
                'text-yellow-500 dark:text-yellow-400': score > 25 && score <= 50,
                'text-green-500 dark:text-green-400': score > 50
            }
        },
        formatCurrency(value) {
            return new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            }).format(value / 100)
        },
        formatDate(date) {
            return new Date(date).toLocaleDateString('pt-BR')
        }
    },
    async mounted() {
        const { data } = await axios.get('/api/risk/dashboard')
        this.metrics = data
    }
}
</script>
